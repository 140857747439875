import { AuthStore } from "./stores/AuthStore";
import { handleError } from "./utility/errorUtils";
import { injectable } from "inversify";
import { boundClass } from "autobind-decorator";

const REFRESH_INTERVAL = 60 * 60 * 1000;

@boundClass
@injectable()
export class AuthTokenGuard {
    constructor(private readonly authStore: AuthStore) {
        //
    }

    public keepRefreshing(): void {
        this.tryToRefresh();
        setInterval(() => this.tryToRefresh(), 60 * 1000);
    }

    private tryToRefresh(): void {
        if (
            !this.authStore.authorized ||
            !this.authStore.tokenCreatedAt ||
            !this.authStore.sasTokenCreatedAt
        ) {
            return;
        }

        const authTokenInvalid =
            new Date().getTime() - this.authStore.tokenCreatedAt > REFRESH_INTERVAL;
        const sasTokenInvalid =
            new Date().getTime() - this.authStore.sasTokenCreatedAt > REFRESH_INTERVAL;

        if (authTokenInvalid || sasTokenInvalid) {
            this.authStore.refresh().catch(handleError);
        }
    }
}
