
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
// @ts-ignore
import Treeselect from "@riophae/vue-treeselect";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";
import { getAncestors, isNthLevel } from "../../../../common/src/utility/treeUtils";

@Component({
    components: { FormFeedback, FormLabel, Treeselect },
    inheritAttrs: false,
})
export default class FormTreeselect extends Vue {
    @Prop() label!: string;
    @Prop() value!: any[];
    @Prop({ default: false }) required!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;
    @Ref("input") readonly input!: any;
    focused: boolean = false;

    get listeners() {
        return {
            ...this.$listeners,
            input: this.onInput,
            close: this.unfocus,
        };
    }

    onInput(event: any[]): void {
        const values = (event || [])
            .flatMap((tag) => getAncestors(tag))
            .filter((tag) => !isNthLevel(tag, 0));

        this.$emit("input", [...new Set(values)]);
    }

    focus() {
        this.focused = true;
        this.$nextTick(() => this.input.openMenu());
    }

    unfocus() {
        this.focused = false;
    }
}
