import { Axios, PastProjectItem, ToJson, UrlFactory } from "@gsx/common";
import {
    CreatePastProjectResponse,
    PastProjectListQuery,
    PastProjectListResponse,
} from "@gsx/common/src/types/http/admin/organizationPastProject";
import { adaptPastProject } from "../../utility/entity/pastProject";

export class OrganizationPastProject {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/organizations");
    }

    public async list(
        organizationId: string,
        params: PastProjectListQuery,
    ): Promise<PastProjectListResponse> {
        const url = this.urlFactory.create(`/${organizationId}/past-projects`);
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptPastProject),
        };
    }

    public async create(
        organizationId: string,
        body: Partial<ToJson<PastProjectItem>>,
    ): Promise<CreatePastProjectResponse> {
        const url = this.urlFactory.create(`/${organizationId}/past-projects`);
        const response = await this.axios.post(url, body);
        return response.data;
    }
}
