import { Api } from "../../../../../common/src/api/Api";
import { RawRequest, RequestListResponse } from "@gsx/common";
import { boundMethod } from "autobind-decorator";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";

@Module()
export class RequestListStore extends ListStoreVuex<RawRequest> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    @boundMethod
    protected async fetch(): Promise<RequestListResponse> {
        return this.api.user.getRequests();
    }
}
