import { randomString } from "@gsx/common";
import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";

// TODO Use vuex

export enum NotificationType {
    Primary = "primary",
    Secondary = "secondary",
    Accent = "accent",
    Success = "success",
    Info = "info",
    Warning = "warning",
    Danger = "danger",
}

interface NotificationOptions {
    timeout?: number;
}

interface NotificationItem extends NotificationOptions {
    id: string;
    title: string;
    type: NotificationType;
}

@injectable()
export class NotificationStore {
    public notifications: Map<string, NotificationItem> = new Map();

    public get all(): NotificationItem[] {
        return [...this.notifications.values()];
    }

    public primary(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Primary, options);
    }

    public secondary(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Secondary, options);
    }

    public accent(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Accent, options);
    }

    public success(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Success, options);
    }

    public info(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Info, options);
    }

    public warning(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Warning, options);
    }

    public danger(title: string, options: NotificationOptions = {}): void {
        this.show(title, NotificationType.Danger, options);
    }

    public show(title: string, type: NotificationType, options: NotificationOptions = {}): void {
        const id = randomString();
        const item = { id, title, type, ...options };

        this.notifications.set(id, item);
        this.triggerChange();

        setTimeout(() => this.close(id), options.timeout || 5000);
    }

    @boundMethod
    public close(id: string): void {
        this.notifications.delete(id);
        this.triggerChange();
    }

    private triggerChange(): void {
        this.notifications = new Map(this.notifications);
    }
}
