import countryList from "./lib/country.json";
import stateList from "./lib/state.json";
import cityList from "./lib/city.json";
import { ICountry, ICity, IState } from "./src/interface";
export { ICountry, ICity, IState } from "./src/interface";

export default {
    getCountryById(id: string): ICountry {
        return _findEntryById(countryList, id);
    },
    getStateById(id: string): IState {
        return _findEntryById(stateList, id);
    },
    getCityById(id: string): ICity {
        return _findEntryById(cityList, id);
    },
    getStatesOfCountry(countryId: string): IState[] {
        const states = stateList.filter(function(value: any) {
            return value.country_id === countryId;
        });
        return states.sort(compare);
    },
    getCitiesOfState(stateId: string): ICity[] {
        const cities = cityList.filter(function(value: any) {
            return value.state_id === stateId;
        });
        return cities.sort(compare);
    },
    getAllCountries(): ICountry[] {
        return countryList;
    },
    getCountryByCode(code: string): ICountry {
        return _findEntryByCode(countryList, code);
    },
    getCodeByCountry(country: ICountry["name"]): ICountry["sortname"] {
        const foundCountry = countryList.find(countryObj => countryObj.name === country);
        return foundCountry ? foundCountry.sortname : "";
    },
};

const _findEntryById = (source: any, id: string) => {
    if (id && source !== null) {
        const idx = source.findIndex((c: any) => c.id === id);
        return idx !== -1 ? source[idx] : "";
    }

    return "";
};

const _findEntryByCode = (source: any, code: string) => {
    if (code && source !== null) {
        const codex = source.findIndex((c: any) => c.sortname === code);
        return codex !== -1 ? source[codex] : "";
    }

    return "";
};

function compare(a: any, b: any) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}
