import { PastProject } from "./PastProject";
import {
    Axios,
    ChangePasswordBody,
    OrganizationProfileBody,
    RawRequest,
    RawUser,
    RefreshTokenResponse,
    RequestAffiliationBody,
    RequestListResponse,
    SubmitFeedbackQuery,
    ToJson,
    UpdateUserProfileBody,
    UrlFactory,
} from "@gsx/common";
import { adaptDate } from "../adapters";
import { adaptUser } from "../../utility/entity/user";
import { SubscriptionQuoteBody } from "@gsx/common/src/types/http/user/subscriptionQuote";
import { AdvertisingQuoteBody } from "@gsx/common/src/types/http/user/advertisingQuote";
import { OrderListItem, OrderListResponse } from "@gsx/common/src/types/http/user/order";

const adaptRequest = (data: ToJson<RawRequest>): RawRequest => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate)!,
});

const adaptOrder = (data: ToJson<OrderListItem>): OrderListItem => ({
    ...data,
    createdAt: adaptDate(data.createdAt)!,
});

export class User {
    private readonly urlFactory: UrlFactory;

    public constructor(
        public readonly pastProject: PastProject,
        private readonly axios: Axios,
        urlFactory: UrlFactory,
    ) {
        this.urlFactory = urlFactory.to("/user");
    }

    public async refreshToken(): Promise<RefreshTokenResponse> {
        const url = this.urlFactory.create("/token");
        const response = await this.axios.post(url);
        return response.data;
    }

    public async me(): Promise<RawUser> {
        const url = this.urlFactory.create("/me");
        const response = await this.axios.get(url);
        return adaptUser(response.data);
    }

    public async changePassword(body: ChangePasswordBody): Promise<void> {
        const url = this.urlFactory.create("/password");
        await this.axios.put(url, body);
    }

    public async getRequests(): Promise<RequestListResponse> {
        const url = this.urlFactory.create("/requests");
        const response = await this.axios.get(url);
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async getOrders(): Promise<OrderListResponse> {
        const url = this.urlFactory.create("/orders");
        const response = await this.axios.get(url);
        return {
            ...response.data,
            data: response.data.data.map(adaptOrder),
        };
    }

    public async updateUserOrganization(
        body: Partial<ToJson<OrganizationProfileBody>>,
    ): Promise<void> {
        const url = this.urlFactory.create("/organization");
        await this.axios.put(url, body);
    }

    public async updateUserOrganizationLogo(body: FormData): Promise<void> {
        const url = this.urlFactory.create("/organization/logo");
        await this.axios.put(url, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    public async affiliateUserWithOrganization(body: RequestAffiliationBody): Promise<void> {
        const url = this.urlFactory.create("/organization/affiliation");
        await this.axios.post(url, body);
    }

    public async updateProfile(body: Partial<ToJson<UpdateUserProfileBody>>): Promise<void> {
        const url = this.urlFactory.create("/profile");
        const response = await this.axios.put(url, body);
        return response.data;
    }

    public async updateProfilePicture(body: FormData): Promise<void> {
        const url = this.urlFactory.create("/profile/picture");
        await this.axios.put(url, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    public async requestSubscriptionQuote(
        body: Partial<ToJson<SubscriptionQuoteBody>>,
        recaptchaToken: string,
    ): Promise<void> {
        const url = this.urlFactory.create("/subscription-quote");
        const params: SubmitFeedbackQuery = { "g-recaptcha-response": recaptchaToken };
        await this.axios.post(url, body, { params });
    }

    public async requestAdvertisingQuote(
        body: Partial<ToJson<AdvertisingQuoteBody>>,
        recaptchaToken: string,
    ): Promise<void> {
        const url = this.urlFactory.create("/adv-quote");
        const params: SubmitFeedbackQuery = { "g-recaptcha-response": recaptchaToken };
        await this.axios.post(url, body, { params });
    }
}
