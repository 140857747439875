import { Api } from "../api/Api";
import { injectable } from "inversify";
import { SubmitStore } from "../stores/general/SubmitStore";
import { SubscriptionQuoteBody } from "@gsx/common/src/types/http/user/subscriptionQuote";
import { marshal, Nullable } from "@gsx/common";
import { RecaptchaService } from "../utility/recaptcha/RecaptchaService";

@injectable()
export class SubmitSubscriptionQuoteStore extends SubmitStore<SubscriptionQuoteBody> {
    readonly data: Nullable<SubscriptionQuoteBody> = {
        subscription: "Global Space Exchange",
        organizationName: "",
        name: "",
        email: "",
        phoneNumber: "",
        numberOfEmployees: null,
        numberOfLicenses: null,
    };

    constructor(private readonly api: Api, private readonly recaptchaService: RecaptchaService) {
        super();
    }

    protected async request(): Promise<void> {
        const recaptchaToken = await this.recaptchaService.verify("request_subscription_quote");
        const body = marshal(this.data);
        await this.api.user.requestSubscriptionQuote(body, recaptchaToken);
    }
}
