import { TreeStructure } from "@gsx/common";

export const isNthLevel = (tag: string, level: number): boolean =>
    tag.split(":").length - 1 === level;

export const flattenTree = (list: TreeStructure[]): TreeStructure[] => [
    ...list,
    ...list.flatMap((node) => flattenTree(node.children || [])),
];

export const getAncestors = (node: string): string[] =>
    node.split(":").map((_value, index, array) => array.slice(0, index + 1).join(":"));
