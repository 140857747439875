import { Axios, QuiltySubscriptionPlanType, ToJson, UrlFactory } from "@gsx/common";
import {
    UserRepresentationListResponse,
    UserListQuery,
    UserRepresentationRawListResponse,
} from "@gsx/common/src/types/http/admin/user";
import { adaptUserRepresentation } from "../../utility/entity/user";
import {
    UserActivityLogListQuery,
    UserActivityLogListResponse,
} from "@gsx/common/src/types/http/admin/userActivityLog";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";

export class User {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/users");
    }

    public async list(): Promise<UserRepresentationListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url);
        return {
            ...response.data,
            data: response.data.data.map(adaptUserRepresentation),
        };
    }

    public async dashboardList(params?: UserListQuery): Promise<UserRepresentationRawListResponse> {
        const url = this.urlFactory.create("/dashboard");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptUserRepresentation),
        };
    }

    public async listActivityLogs(
        params?: UserActivityLogListQuery,
    ): Promise<UserActivityLogListResponse> {
        const url = this.urlFactory.create("/activity");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptUserRepresentation),
        };
    }

    public async delete(userid: string): Promise<void> {
        const url = this.urlFactory.create(`/${userid}`);
        await this.axios.delete(url);
    }

    public async updateOrganization(userid: string, organizationId: string): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/organization/${organizationId}`);
        await this.axios.put(url);
    }

    public async updateProfile(
        userId: string,
        body: Partial<ToJson<{ contractId: string }>>,
    ): Promise<void> {
        const url = this.urlFactory.create(`/update/${userId}`);
        const response = await this.axios.put(url, body);
        return response.data;
    }

    public async createSubscription(userid: string, planType: SubscriptionPlanType): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/subscription/create`);
        await this.axios.post(url, { planType });
    }

    public async addToLicense(
        userid: string,
        licenseUser: { email: string; firstName: string; lastName: string },
    ): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/addLicense`);
        await this.axios.post(url, licenseUser);
    }

    public async removeFromLicense(userid: string, email: string): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/removeLicense/${email}`);
        await this.axios.delete(url);
    }

    public async updateSubscription(
        userid: string,
        subscriptionUpdate: {
            active?: boolean;
            endsAt?: string;
            planType?: SubscriptionPlanType;
        },
    ): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/subscription/update`);
        await this.axios.put(url, subscriptionUpdate);
    }

    public async createQuiltySubscription(
        userid: string,
        planType: QuiltySubscriptionPlanType,
    ): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/quilty-subscription/create`);
        await this.axios.post(url, { planType });
    }

    public async updateQuiltySubscription(
        userid: string,
        subscriptionUpdate: {
            active?: boolean;
            endsAt?: string;
            planType?: QuiltySubscriptionPlanType;
        },
    ): Promise<void> {
        const url = this.urlFactory.create(`/${userid}/quilty-subscription/update`);
        await this.axios.put(url, subscriptionUpdate);
    }

    public async createUser(
        user: {
            firstName: string;
            lastName: string;
            email: string;
            organization?: {
                id?: string;
                name: string;
            };
        },
        picture: FormData,
    ): Promise<string> {
        const url = this.urlFactory.create(`/create`);
        const response = await this.axios
            .post(url, {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                organizationId: user.organization?.id,
                organizationName: user.organization?.name,
            })
            .then(async (accountCreationResponse) => {
                if (picture) {
                    this.uploadUserPicture(accountCreationResponse.data.id, picture).catch();
                }
                return accountCreationResponse;
            });

        return response.data.id;
    }

    private async uploadUserPicture(id: string, formData: FormData) {
        const userUpdate = this.urlFactory.create(`/update/${id}`);

        await this.axios.put(userUpdate, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }
}
