
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { createIdFromLabel } from "./utils";
import { randomString, stringToNumber, toDateUtc, toDateUtcString } from "@gsx/common";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";
import { InputType } from "./types";

@Component({
    components: { FormFeedback, FormLabel },
})
export default class FormInput extends Vue {
    @Prop() label!: string;
    @Prop([String, Date, Number]) value!: any;
    @Prop({ default: InputType.Text }) type!: InputType;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) readonly!: boolean;
    @Prop({ default: false }) autofocus!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;

    @Ref("input") readonly input!: HTMLInputElement;

    mounted() {
        if (this.autofocus) {
            this.input.focus();
        }
    }

    get id(): string | null {
        return this.label ? createIdFromLabel(this.label) : randomString();
    }

    get formattedValue(): string | null {
        return this.marshalValue(this.value);
    }

    onChange(event: any) {
        if (!this.readonly) {
            const value = this.unmarshalValue(event.target.value);
            this.$emit("input", value);
        }
    }

    marshalValue(value: any): string | null {
        if (value === null || value === undefined) {
            return null;
        }

        if (this.type === InputType.Date) {
            return toDateUtcString(value);
        }

        if (this.type === InputType.Number) {
            return String(value);
        }

        return value;
    }

    unmarshalValue(value: string | null): any {
        if (this.type === InputType.Date) {
            return toDateUtc(value);
        }

        if (this.type === InputType.Number) {
            return stringToNumber(value);
        }

        return value;
    }
}
