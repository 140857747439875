import { OrganizationFavorites } from "./OrganizationFavorites";
import { ConferenceFavorites } from "./ConferenceFavorites";
import { AnalyticFavorites } from "./AnalyticFavorites";

export class UserFavorites {
    public constructor(
        public readonly conference: ConferenceFavorites,
        public readonly organization: OrganizationFavorites,
        public readonly analytics: AnalyticFavorites,
    ) {
        //
    }
}
