import { Admin } from "./admin/Admin";
import { Guest } from "./guest/Guest";
import { User } from "./user/User";
import { Payment } from "./payment/Payment";
import { Dashboard } from "./dashboard/Dashboard";
import { Launchpad } from "./launchpad/Launchpad";

export class Api {
    public constructor(
        public readonly admin: Admin,
        public readonly user: User,
        public readonly guest: Guest,
        public readonly payment: Payment,
        public readonly dashboard: Dashboard,
        public readonly launchpad: Launchpad,
    ) {
        //
    }
}
