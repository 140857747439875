import { Mutation, RegisterOptions, VuexModule } from "vuex-class-modules";
import { SortOrder } from "@gsx/common";
import { compare } from "../../../landing/src/utility/utils";
import ListStoreVuex from "./general/ListStoreVuex";
import get from "lodash.get";

export interface FilterableListStore<I> {
    filteredItems: I[];
}

export abstract class CollectionStore<I> extends VuexModule {
    abstract sort: keyof I;
    page: number = 1;
    pageSize: number = 10;
    order: SortOrder = SortOrder.Asc;

    constructor(
        protected readonly listStore: FilterableListStore<I> | ListStoreVuex<I>,
        options: RegisterOptions,
    ) {
        super(options);
    }

    get list() {
        const list: I[] =
            (this.listStore as FilterableListStore<I>).filteredItems ??
            (this.listStore as ListStoreVuex<I>).items ??
            [];
        return list;
    }

    get pageCount(): number {
        const count = this.list.length;
        return Math.max(1, Math.ceil(count / this.pageSize));
    }

    get items(): I[] {
        const start = (this.page - 1) * this.pageSize;
        const end = start + this.pageSize;

        return this.list
            .sort((a, b) =>
                compare(get(a, this.sort), get(b, this.sort), this.order === SortOrder.Asc),
            )
            .slice(start, end);
    }

    @Mutation
    changePage(page: number) {
        this.page = page;
    }

    @Mutation
    changePageSize(pageSize: number) {
        this.page = 1;
        this.pageSize = pageSize;
    }

    @Mutation
    changeSort(sort: keyof I) {
        this.order =
            sort !== this.sort || (sort === this.sort && this.order === SortOrder.Desc)
                ? SortOrder.Asc
                : SortOrder.Desc;

        this.sort = sort;
        this.page = 1;
    }
}
