import { Axios, ToJson, UrlFactory } from "@gsx/common";
import {
    ConferenceListResponse,
    ConferenceBody,
    CreateConferenceResponse,
    ConferenceListItem,
    ConferenceListQuery,
} from "@gsx/common/src/types/http/dashboard/conference";
import { adaptConference } from "../../utility/entity/conference";
import { adaptDateUtc } from "../adapters";

export class Conference {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/conferences");
    }

    public async list(params: ConferenceListQuery): Promise<ConferenceListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map((conference: ToJson<ConferenceListItem>) => ({
                ...adaptConference(conference),
                createdAt: adaptDateUtc(conference.createdAt),
            })),
        };
    }

    public async create(
        body: Partial<ToJson<ConferenceBody>>,
        formData: FormData,
    ): Promise<CreateConferenceResponse> {
        const url = this.urlFactory.create("/");

        const response = await this.axios.post(url, body).then(async (res) => {
            await this.uploadConferenceLogo(res.data.id, formData);
            return res;
        });

        return response.data;
    }

    public async update(
        id: string,
        body: Partial<ToJson<ConferenceBody>>,
        formData: FormData,
    ): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.put(url, body).then(async () => {
            await this.uploadConferenceLogo(id, formData);
        });
    }

    public async delete(id: string): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.delete(url);
    }

    private async uploadConferenceLogo(id: string, formData: FormData) {
        if (formData.has("logo") && formData.get("logo") !== "null") {
            const urlLogo = this.urlFactory.create(`/${id}/logo`);

            await this.axios.put(urlLogo, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        }
    }
}
