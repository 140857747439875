import { Organization, ToJson } from "@gsx/common";
import { adaptDate, adaptDateUtc } from "../../api/adapters";
import { CheckListBody } from "@gsx/common/src/types/http/user/organization/checkList";

export const adaptOrganization = <T extends Partial<Organization>>(
    data: ToJson<T>,
): Partial<Organization> => {
    const output: Partial<Organization> = {};

    if (data.acquired) {
        output.acquired = adaptDateUtc(data.acquired);
    }

    if (data.updatedAt) {
        output.updatedAt = adaptDate(data.updatedAt);
    }

    return { ...data, ...output };
};

export const adaptCheckList = <T extends Partial<CheckListBody>>(
    data: ToJson<T>,
): CheckListBody => ({
    ...data,
    contactCompanyDate: adaptDateUtc(data.contactCompanyDate),
    contactCompanyUserId: data.contactCompanyUserId ?? null,
    updatedAt: adaptDate(data.updatedAt),
    publicRecordsDate: adaptDateUtc(data.publicRecordsDate),
    publicRecordsUserId: data.publicRecordsUserId ?? null,
    published: data.published ?? null,
    regulatoryRecordsDate: adaptDateUtc(data.regulatoryRecordsDate),
    regulatoryRecordsUserId: data.regulatoryRecordsUserId ?? null,
    socialMediaDate: adaptDateUtc(data.socialMediaDate),
    socialMediaUserId: data.socialMediaUserId ?? null,
    websiteDate: adaptDateUtc(data.websiteDate),
    websiteUserId: data.websiteUserId ?? null,
});
