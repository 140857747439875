import * as path from "path";
import uuid from "uuid";

// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
export const escapeRegExp = (text: string): string => text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const stringToNumber = (value: string | undefined | null): number | undefined => {
    if (value === null || value === undefined || value.length === 0) {
        return undefined;
    }

    const output = Number(value);

    if (isNaN(output)) {
        return undefined;
    }

    return output;
};

export const capitalize = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1);

export const isProduction = (): boolean => process.env.NODE_ENV === "production";
export const isStripeLive = (): boolean => (process.env.STRIPE_SECRET || "").includes("_live_");

export const getAbsolutePath = (text: string): string => path.resolve(__dirname, "../../..", text);

export const generateId = (): string => uuid.v4().replace(/-/g, "");
