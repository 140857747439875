import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";

@injectable()
export class AdvertisingQuoteModalStore {
    isVisible: boolean = false;

    @boundMethod
    open() {
        this.isVisible = true;
    }

    @boundMethod
    close() {
        this.isVisible = false;
    }
}
