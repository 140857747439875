
import { Component, Inject, Vue } from "vue-property-decorator";
import FormTextarea from "../../../landing/src/molecules/form/FormTextarea.vue";
import { Container } from "inversify";
import { NotificationStore } from "../stores/NotificationStore";
import ActionButton from "../components/ActionButton.vue";
import Modal from "../components/Modal.vue";
import RecaptchaDisclosure from "../../../landing/src/molecules/recaptcha/RecaptchaDisclosure.vue";
import FormInput from "../../../landing/src/molecules/form/FormInput.vue";
import FormTel from "../../../landing/src/molecules/form/FormTel.vue";
import { PaymentService } from "../../../landing/src/utility/payment/PaymentService";
import { handleError } from "../utility/errorUtils";
import { OrderType } from "@gsx/common";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";
import { IndividualSubscriptionRedirectModalStore } from "./IndividualSubscriptionRedirectModalStore";
import { event } from "vue-gtag";

@Component({
    components: {
        FormTel,
        FormInput,
        RecaptchaDisclosure,
        ActionButton,
        Modal,
        FormTextarea,
    },
})
export default class IndividualSubscriptionRedirectModal extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly modalStore: IndividualSubscriptionRedirectModalStore = this.container.get(
        IndividualSubscriptionRedirectModalStore,
    );

    readonly paymentService: PaymentService = this.container.get(PaymentService);

    async submit() {
        try {
            await this.paymentService.purchasePlan(SubscriptionPlanType.Individual);
            event("Purchase", {
                eventCategory: "Purchase",
                eventAction: OrderType.RequestSubscriptionQuote,
                eventLabel: OrderType.RequestSubscriptionQuote,
            });
        } catch (e: any) {
            this.notificationStore.warning("Could not initialize a payment process.");
            handleError(e);
        } finally {
            this.modalStore.close();
        }
    }
}
