import { Axios, UrlFactory } from "@gsx/common";
import {
    RequestListItem,
    RequestListQuery,
    RequestListResponse,
    RequestStatusBody,
} from "@gsx/common/src/types/http/admin/request";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): RequestListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class Request {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/requests");
    }

    public async list(params: RequestListQuery): Promise<RequestListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async changeStatus(id: string, body: RequestStatusBody): Promise<void> {
        const url = this.urlFactory.create(`/${id}/status`);
        await this.axios.put(url, body);
    }
}
