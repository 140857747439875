import { Api } from "../../../../../common/src/api/Api";
import { injectable } from "inversify";
import { ChangePasswordBody, marshal } from "@gsx/common";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";

@injectable()
export class ChangePasswordStore extends SubmitStore<ChangePasswordBody> {
    readonly data: ChangePasswordBody = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    };

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.user.changePassword(body);
    }

    clear() {
        this.data.oldPassword = "";
        this.data.newPassword = "";
        this.data.confirmNewPassword = "";
    }
}
