import { Conference, marshal, Nullable, ToJson } from "@gsx/common";
import { adaptDateUtc } from "../../api/adapters";
import get from "lodash.get";
import {
    ConferenceBody,
    ConferenceListItem,
} from "@gsx/common/src/types/http/dashboard/conference";
import moment from "moment";

export const adaptConference = <T extends Partial<Conference>>(
    data: ToJson<T>,
): Partial<Conference> => ({
    ...data,
    deadlines: data.deadlines?.map((deadline) => ({
        ...deadline,
        date: adaptDateUtc(deadline.date) || new Date(),
    })),
    startDate: adaptDateUtc(data.startDate),
    endDate: adaptDateUtc(data.endDate),
    updatedAt: adaptDateUtc(data.updatedAt),
});

export const createConferenceEmptyBody = (
    conference?: Omit<ConferenceListItem, "conferenceid" | "userId">,
): Nullable<ConferenceBody> => ({
    city: get(conference, "city", null),
    country: get(conference, "country", null),
    deadlines: get(conference, "deadlines", null),
    details: get(conference, "details", null),
    endDate: get(conference, "endDate", null),
    logo: get(conference, "logo", null),
    name: get(conference, "name", null),
    organizingOrganizations: get(conference, "organizingOrganizations", null),
    startDate: get(conference, "startDate", null),
    state: get(conference, "state", null),
    topics: get(conference, "topics", null),
    types: get(conference, "types", null),
    coordinates: get(conference, "coordinates", null),
    createdAt: get(conference, "createdAt", null),
    updatedAt: get(conference, "updatedAt", null),
    url: get(conference, "url", null),
});

export const marshalConference = (
    data: Nullable<ConferenceBody>,
): Partial<ToJson<ConferenceBody>> => {
    const json: Nullable<ToJson<ConferenceBody>> = {
        ...data,
        deadlines: data.deadlines
            ? data.deadlines.map((deadline) => ({
                  date: moment(deadline.date).format(),
                  title: deadline.title,
              }))
            : null,
        startDate: data.startDate ? moment(data.startDate).format() : null,
        endDate: data.endDate ? moment(data.endDate).format() : null,
        createdAt: data.createdAt ? moment(data.createdAt).format() : null,
        updatedAt: data.updatedAt ? moment(data.updatedAt).format() : null,
    };
    return marshal(json);
};
