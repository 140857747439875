
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import RetryFailed from "./RetryFailed.vue";

@Component({
    components: { RetryFailed },
})
export default class LoadingOverlay extends Vue {
    @Prop({ default: false }) loading!: boolean;
    @Prop({ default: false }) failed!: boolean;
    @Prop({ default: "div" }) tag!: string;

    @Emit()
    retry() {
        //
    }
}
