
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { SelectOption } from "./types";
import FormMultiSelect from "./FormMultiSelect.vue";

@Component({
    components: { FormMultiSelect },
    inheritAttrs: false,
})
export default class FormSimpleMultiSelect extends Vue {
    @Prop([String, Array]) value!: string | string[];
    @Prop({ default: () => [] }) options!: string[];

    get valueFormatted(): SelectOption[] {
        const values = this.value ? (Array.isArray(this.value) ? this.value : [this.value]) : [];
        return values.map((value) => ({
            id: value,
            name: value,
        }));
    }

    get listeners() {
        return {
            ...this.$listeners,
            input: this.input,
        };
    }

    @Emit()
    input(item: SelectOption | SelectOption[] | null): string | string[] | null {
        if (!item) {
            return item;
        }

        if (Array.isArray(item)) {
            return item.map((item) => item.id);
        }

        return item.id;
    }
}
