import { Axios, UrlFactory } from "@gsx/common";
import {
    QuiltyInsightsBody,
    QuiltyInsightsListItem,
    QuiltyInsightsListQuery,
    QuiltyInsightsListResponse,
} from "@gsx/common/src/types/http/dashboard/quiltyInsights";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): QuiltyInsightsListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class QuiltyInsights {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/quiltyInsights");
    }

    public async list(params: QuiltyInsightsListQuery): Promise<QuiltyInsightsListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async get(id: string): Promise<QuiltyInsightsBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as QuiltyInsightsBody;
    }

    public async toggle(id: string): Promise<QuiltyInsightsBody> {
        const url = this.urlFactory.create(`/${id}/like`);
        const response = await this.axios.post(url);
        return response.data as QuiltyInsightsBody;
    }
}
