import countries, { ICity, ICountry, IState } from "../../../vendor/country-state-city";

const findByName = <T extends { name: string }>(items: T[], needle: string | null): T | null => {
    if (needle) {
        const formatted = needle.toLowerCase();
        return items.find((country) => country.name.toLowerCase() === formatted) || null;
    }

    return null;
};

export const getCountry = (text: string | null): ICountry | null => {
    const country = findByName(countries.getAllCountries(), text);

    if (country) {
        return country;
    }

    if (text) {
        return {
            id: "",
            name: text,
            phonecode: "",
            sortname: "",
        };
    }

    return null;
};

export const getState = (country: ICountry | null, text: string | null): IState | null => {
    if (country && country.id) {
        const state = findByName(countries.getStatesOfCountry(country.id), text);

        if (state) {
            return state;
        }
    }

    if (text) {
        return {
            id: "",
            name: text,
            country_id: "",
        };
    }

    return null;
};

export const getCity = (state: IState | null, text: string | null): ICity | null => {
    if (state && state.id) {
        const city = findByName(countries.getCitiesOfState(state.id), text);

        if (city) {
            return city;
        }
    }

    if (text) {
        return {
            id: "",
            name: text,
            state_id: "",
        };
    }

    return null;
};
