import {
    Axios,
    SignInBody,
    SignInResponse,
    SignUpBody,
    SignUpQuery,
    SignUpResponse,
    SubmitFeedbackBody,
    SubmitFeedbackQuery,
    SubmitRequestBody,
    SubmitRequestQuery,
    UrlFactory,
} from "@gsx/common";
import { Organization } from "./Organization";
import {
    ChangePasswordBody,
    PasswordResetBody,
} from "@gsx/common/src/types/http/guest/passwordReset";
import {
    EmailVerificationBody,
    EmailVerificationQuery,
} from "@gsx/common/src/types/http/guest/emailVerification";
import { adaptUser } from "../../utility/entity/user";
import { SearchQuery, SearchResultsResponse } from "@gsx/common/src/types/http/guest/search";
import { Statistics } from "./Statistics";

export class Guest {
    private readonly urlFactory: UrlFactory;

    public constructor(
        public readonly organization: Organization,
        public readonly statistics: Statistics,
        private readonly axios: Axios,
        urlFactory: UrlFactory,
    ) {
        this.urlFactory = urlFactory.to("/");
    }

    public async signup(
        body: Partial<SignUpBody>,
        recaptchaToken: string,
        platformReferer: string,
    ): Promise<SignUpResponse> {
        let url = this.urlFactory.create("/signup");

        if (!body.emailVerificationCodeNeeded) {
            url = this.urlFactory.create("/signup-v2");
        }

        const params: SignUpQuery = { "g-recaptcha-response": recaptchaToken };
        const response = await this.axios.post(url, body, { params, headers: { platformReferer } });
        return response.data;
    }

    public async signin(body: SignInBody): Promise<SignInResponse> {
        const url = this.urlFactory.create("/signin");
        const response = await this.axios.post(url, body);
        return {
            ...response.data,
            user: adaptUser(response.data.user),
        };
    }

    public async emailExists(email: string, recaptchaToken: string): Promise<boolean> {
        const url = this.urlFactory.create("/email-exists");
        const params = { "g-recaptcha-response": recaptchaToken };
        const response = await this.axios.post(url, { email }, { params });
        return response.data;
    }

    public async getTree(id: string, type?: string): Promise<any[]> {
        const url = this.urlFactory.create(`/trees/${id}`);
        const response = await this.axios.get(url, { params: { type } });
        return response.data;
    }

    public async getFieldOptions(id: string): Promise<any[]> {
        const url = this.urlFactory.create(`/fields/${id}/options`);
        const response = await this.axios.get(url);
        return response.data;
    }

    public async search(params: SearchQuery): Promise<SearchResultsResponse> {
        const url = this.urlFactory.create("/search");
        const response = await this.axios.get(url, { params });
        return response.data;
    }

    public async submitRequest(
        body: Partial<SubmitRequestBody>,
        recaptchaToken: string,
    ): Promise<void> {
        const url = this.urlFactory.create("/requests");
        const params: SubmitRequestQuery = { "g-recaptcha-response": recaptchaToken };
        await this.axios.post(url, body, { params });
    }

    public async submitFeedback(body: SubmitFeedbackBody, recaptchaToken: string): Promise<void> {
        const url = this.urlFactory.create("/feedback");
        const params: SubmitFeedbackQuery = { "g-recaptcha-response": recaptchaToken };
        await this.axios.post(url, body, { params });
    }

    public async resetPassword(
        body: Partial<PasswordResetBody>,
        platformReferer: string,
    ): Promise<void> {
        const url = this.urlFactory.create("/password-reset");
        await this.axios.post(url, body, { headers: { platformReferer } });
    }

    public async changePassword(token: string, body: Partial<ChangePasswordBody>): Promise<void> {
        const url = this.urlFactory.create(`/password-reset/${token}`);
        await this.axios.post(url, body);
    }

    public async emailVerification(
        body: Partial<EmailVerificationBody>,
        recaptchaToken: string,
    ): Promise<void> {
        const url = this.urlFactory.create("/verification/email");
        const params: EmailVerificationQuery = { "g-recaptcha-response": recaptchaToken };
        await this.axios.post(url, body, { params });
    }
}
