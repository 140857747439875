import { Axios, ToJson, UrlFactory } from "@gsx/common";
import {
    MergerAcquisitionBody,
    MergerAcquisitionListItem,
    MergerAcquisitionListQuery,
    MergerAcquisitionListResponse,
} from "@gsx/common/src/types/http/admin/mergerAcquisition";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): MergerAcquisitionListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class MergerAcquisition {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/mergerAcquisitions");
    }

    public async list(params: MergerAcquisitionListQuery): Promise<MergerAcquisitionListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async create(body: Partial<ToJson<MergerAcquisitionBody>>): Promise<void> {
        const url = this.urlFactory.create(`/`);
        await this.axios.post(url, body);
    }

    public async update(id: string, body: Partial<ToJson<MergerAcquisitionBody>>): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.put(url, body);
    }

    public async get(id: string): Promise<MergerAcquisitionBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as MergerAcquisitionBody;
    }

    public async delete(id: string): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.delete(url);
    }
}
