import { Axios, UrlFactory } from "@gsx/common";
import { StatisticResponse } from "@gsx/common/src/types/http/dashboard/statistic";

export class Statistics {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/statistics");
    }

    public async get(): Promise<StatisticResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url);
        return response.data;
    }
}
