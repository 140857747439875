
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
    @Prop() title!: string;

    created() {
        window.addEventListener("keyup", this.handleKeyPress);
    }

    destroyed() {
        window.removeEventListener("keyup", this.handleKeyPress);
    }

    @Emit()
    close() {
        //
    }

    handleKeyPress(e: any) {
        if (e.key === "Escape") {
            this.close();
        }
    }
}
