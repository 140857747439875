
import { Component, Inject, Vue } from "vue-property-decorator";
import RequestForm from "../../../landing/src/molecules/request-form/RequestForm.vue";
import FormInput from "../../../landing/src/molecules/form/FormInput.vue";
import FormTextarea from "../../../landing/src/molecules/form/FormTextarea.vue";
import FormSelect from "../../../landing/src/molecules/form/FormSelect.vue";
import { Container } from "inversify";
import { NotificationStore } from "../stores/NotificationStore";
import { SubmitFeedbackStore } from "./SubmitFeedbackStore";
import { AuthStore } from "../stores/AuthStore";
import { FeedbackType } from "@gsx/common";
import { focusOnFirstInvalidInput } from "../form/utils";
import RecaptchaDisclosure from "../../../landing/src/molecules/recaptcha/RecaptchaDisclosure.vue";
import { FeedbackModalStore, FeedbackModalType } from "./FeedbackModalStore";
import ActionButton from "../components/ActionButton.vue";
import Modal from "../components/Modal.vue";

@Component({
    components: {
        RecaptchaDisclosure,
        ActionButton,
        FormSelect,
        Modal,
        FormTextarea,
        FormInput,
        RequestForm,
    },
})
export default class SubmitFeedbackModal extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly submitFeedbackStore: SubmitFeedbackStore = this.container.get(SubmitFeedbackStore);
    readonly feedbackModalStore: FeedbackModalStore = this.container.get(FeedbackModalStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    created() {
        this.values.pageTriggered = window.location.pathname;

        if (this.feedbackModalStore.type === FeedbackModalType.RecommendOrganization) {
            this.values.type = FeedbackType.RecommendOrganization;
        }
    }

    get values() {
        return this.submitFeedbackStore.data;
    }

    get errors() {
        return this.submitFeedbackStore.formErrors;
    }

    get titleTitle(): string {
        return this.feedbackModalStore.type === FeedbackModalType.RecommendOrganization
            ? "Name of Organization"
            : "Title";
    }

    get feedbackTypes(): FeedbackType[] {
        return Object.values(FeedbackType);
    }

    get getRecommendType() {
        return FeedbackType.RecommendOrganization;
    }

    async submitFeedback() {
        await this.submitFeedbackStore.submit();

        if (this.submitFeedbackStore.failed) {
            this.notificationStore.warning("Submitting feedback has failed.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have submitted the feedback successfully.");
            this.feedbackModalStore.close();
        }
    }
}
