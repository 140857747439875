import { Api } from "../api/Api";
import { injectable } from "inversify";
import { FeedbackType, marshal, SubmitFeedbackBody } from "@gsx/common";
import { RecaptchaService } from "../utility/recaptcha/RecaptchaService";
import { SubmitStore } from "../stores/general/SubmitStore";

@injectable()
export class SubmitFeedbackStore extends SubmitStore<SubmitFeedbackBody> {
    readonly data: SubmitFeedbackBody = {
        title: "",
        description: "",
        type: FeedbackType.Suggestion,
        pageTriggered: "",
        requestingFor: "",
    };

    constructor(private readonly api: Api, private readonly recaptchaService: RecaptchaService) {
        super();
    }

    protected async request(): Promise<void> {
        const recaptchaToken = await this.recaptchaService.verify("feedback");
        const body = marshal(this.data);
        await this.api.guest.submitFeedback(body, recaptchaToken);
    }
}
