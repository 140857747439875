
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ActionButton from "./ActionButton.vue";

@Component({
    components: { ActionButton },
})
export default class RetryFailed extends Vue {
    @Prop() loading!: boolean;
    @Prop({ default: true }) displayContent!: boolean;

    @Emit()
    retry() {
        //
    }
}
