import { RawUser, ToJson } from "@gsx/common";

export const adaptSubscription = (
    data: ToJson<RawUser["subscription"]>,
): RawUser["subscription"] => {
    if (!data) {
        return undefined;
    }

    return {
        ...data,
    };
};
