import { Api } from "../api/Api";
import { RawUser, SignInBody, stringToNumber } from "@gsx/common";
import { Action, Module, RegisterOptions, VuexModule } from "vuex-class-modules";
import { event } from "vue-gtag";

const AUTH_TOKEN_KEY = "token";
const AUTH_TOKEN_CREATED_AT_KEY = "token_created_at";

const SAS_TOKEN_KEY = "sas_token";
const SAS_TOKEN_CREATED_AT_KEY = "sas_token_created_at";

@Module({ generateMutationSetters: true })
export class AuthStore extends VuexModule {
    user: RawUser | null = null;
    loaded: boolean = false;
    tokenCreatedAt: number | null = stringToNumber(localStorage.getItem(AUTH_TOKEN_CREATED_AT_KEY));
    token: string | null = localStorage.getItem(AUTH_TOKEN_KEY);
    sasTokenCreatedAt: number | null = stringToNumber(
        localStorage.getItem(SAS_TOKEN_CREATED_AT_KEY),
    );
    sasToken: string | null = localStorage.getItem(SAS_TOKEN_KEY);

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    get authorized(): boolean {
        return this.user !== null;
    }

    @Action
    async me(): Promise<void> {
        try {
            this.setUser(await this.api.user.me());
        } finally {
            this.loaded = true;
        }
    }

    @Action
    async signin(body: SignInBody): Promise<void> {
        const response = await this.api.guest.signin(body);
        this.setUser(response.user);
        this.storeToken(response.token);
        this.storeSasToken(response.sasToken);
        event("Auth", {
            eventCategory: "Auth",
            eventAction: "Sign in",
            eventLabel: "Sign in",
        });
    }

    @Action
    async refresh(): Promise<void> {
        const response = await this.api.user.refreshToken();
        this.storeToken(response.token);
        this.storeSasToken(response.sasToken);
    }

    @Action
    async signout(): Promise<void> {
        this.unsetUser();
        event("Auth", {
            eventCategory: "Auth",
            eventAction: "Sign out",
            eventLabel: "Sign out",
        });
    }

    private setUser(user: RawUser) {
        this.user = user;
    }

    private unsetUser() {
        this.user = null;
        this.token = null;
        localStorage.removeItem(AUTH_TOKEN_KEY);
        localStorage.removeItem(AUTH_TOKEN_CREATED_AT_KEY);
        localStorage.removeItem(SAS_TOKEN_KEY);
        localStorage.removeItem(SAS_TOKEN_CREATED_AT_KEY);
    }

    private storeToken(token: string) {
        this.token = token;
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        localStorage.setItem(AUTH_TOKEN_CREATED_AT_KEY, String(new Date().getTime()));
    }

    private storeSasToken(token: string) {
        this.sasToken = token;
        localStorage.setItem(SAS_TOKEN_KEY, token);
        localStorage.setItem(SAS_TOKEN_CREATED_AT_KEY, String(new Date().getTime()));
    }
}
