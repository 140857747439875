
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class InputField extends Vue {
    @Prop([Number, String]) value!: string | number;
    @Prop() error?: string;
    @Prop() customClass?: string;
    @Prop({ default: "Enter a value" }) placeholder!: string;

    get formattedValue(): string | null {
        if (this.value === null || this.value === undefined) {
            return null;
        }

        return String(this.value);
    }

    @Emit()
    focus() {
        //
    }
}
