var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"createx"},[_c('div',{staticStyle:{"display":"inline-flex","width":"100%","position":"relative","z-index":"3"}},[_c('div',{staticClass:"progress register",staticStyle:{"flex-grow":"20","background-color":"#e1e9ef","position":"relative"}},[_c('div',{staticClass:"progress-bar",style:({ width: _vm.percentRegistered + '%' }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100"}}),_vm._v(" "),_c('div',{staticClass:"dot",style:({
                    left:
                        _vm.percentRegistered +
                        (_vm.isSubscribed ? 100 : 0) +
                        (_vm.isSubscribed && _vm.isEnterprise ? 100 : 0) +
                        '%',
                })},[_c('span',{staticClass:"globalImage"})]),_vm._v(" "),_c('span',{staticClass:"progress-tooltip"},[_c('h6',{staticStyle:{"color":"#2e8388"}},[_vm._v("Registration")]),_vm._v(" "),_c('ul',{staticStyle:{"list-style":"none","padding":"0","text-align":"left"}},[_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('firstName') &&
                                    _vm.hasRegisteredProperty('lastName')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("First/Last Name")])])]),_vm._v(" "),_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('streetAddress')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("Address")])])]),_vm._v(" "),_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('country')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("Country")])])]),_vm._v(" "),_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('state')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("State/Province")])])]),_vm._v(" "),_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('city')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("City")])])]),_vm._v(" "),_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('zipcode')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("Zipcode")])])]),_vm._v(" "),_c('li',[_c('div',[_c('i',{class:_vm.hasRegisteredProperty('roleInOrganization')
                                        ? 'far fa-check-circle'
                                        : 'far fa-circle'}),_vm._v(" "),_c('label',[_vm._v("Organization Role")])])])])])]),_vm._v(" "),_c('div',{staticStyle:{"flex-grow":"1"}}),_vm._v(" "),_c('div',{staticClass:"progress subscribe",staticStyle:{"flex-grow":"20","background-color":"#e1e9ef"}},[_c('div',{staticClass:"progress-bar",style:({
                    width: _vm.isSubscribed ? 100 + '%' : 0 + '%',
                    visibility: _vm.percentRegistered >= 100 ? 'visible' : 'hidden',
                }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100"}}),_vm._v(" "),_c('span',{staticClass:"progress-tooltip"},[_c('h6',{staticStyle:{"color":"#2e8388"}},[_vm._v("Subscription")]),_vm._v(" "),_c('ul',{staticStyle:{"list-style":"none","padding":"0"}},[_c('li',[(_vm.isSubscribed)?_c('span',{staticStyle:{"text-align":"left"}},[_vm._v("\n                            Subscribed!\n                            "),_c('ActionButton',{staticClass:"btn-primary btn-sm",staticStyle:{"margin-top":"10px"}},[_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"href":"/dashboard"}},[_vm._v("\n                                    Space Dashboard\n                                ")])])],1):_c('label',{staticStyle:{"padding":"0","text-align":"center"}},[_vm._v("\n                            Unlock unlimited access to Space Insights for 900+ organizations\n                            "),_c('ActionButton',{staticClass:"btn-primary btn-sm",staticStyle:{"margin-top":"10px"},on:{"click":_vm.openSubscriptionQuoteModal}},[_vm._v("\n                                Subscribe\n                            ")])],1)])])])]),_vm._v(" "),_c('div',{staticStyle:{"flex-grow":"1"}}),_vm._v(" "),_c('div',{staticClass:"progress enterprise",staticStyle:{"flex-grow":"20","background-color":"#e1e9ef"}},[_c('div',{staticClass:"progress-bar",style:({
                    width: _vm.isSubscribed && _vm.isEnterprise ? 100 + '%' : 0 + '%',
                    visibility: _vm.percentRegistered >= 100 && _vm.isSubscribed ? 'visible' : 'hidden',
                }),attrs:{"role":"progressbar","aria-valuenow":"0","aria-valuemin":"0","aria-valuemax":"100"}}),_vm._v(" "),_c('span',{staticClass:"progress-tooltip"},[_c('h6',{staticStyle:{"color":"#2e8388"}},[_vm._v("Enterprise")]),_vm._v(" "),_c('ul',{staticStyle:{"list-style":"none","padding":"0"}},[_c('li',[(_vm.isEnterprise)?_c('span',[_vm._v("You're registered as Enterprise!")]):_c('label',{staticStyle:{"padding":"0","text-align":"center"}},[_vm._v("\n                            Need to register your Enterprise?\n                            "),_c('ActionButton',{staticClass:"btn-primary btn-sm",staticStyle:{"margin-top":"10px"},on:{"click":_vm.openSubscriptionQuoteModal}},[_vm._v("\n                                Contact Us\n                            ")])],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }