
import { Component, Prop, Vue } from "vue-property-decorator";
import { createIdFromLabel } from "./utils";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";

@Component({
    components: { FormFeedback, FormLabel },
})
export default class FormTel extends Vue {
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) readonly!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;

    initValue = this.value;
    isValid: boolean = true;

    get id(): string {
        return createIdFromLabel(this.label);
    }

    get inputClasses() {
        return ["form-control", { "is-invalid": !this.isValid }];
    }

    get wrapperClasses() {
        return ["custom-tel-input", { "is-invalid": !this.isValid }];
    }

    onInput(formattedNumber: string, response: any) {
        if (!this.readonly) {
            this.isValid = response.isValid || !formattedNumber;
            this.$emit("input", response.number.e164 || formattedNumber);
        }
    }
}
