
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import FormMultiSelect from "./FormMultiSelect.vue";
import { SelectOption } from "./types";
import { capitalizeWords } from "@gsx/common";

@Component({
    components: { FormMultiSelect },
    inheritAttrs: false,
})
export default class FormEnumMultiSelect extends Vue {
    @Prop([String, Array]) value!: string | string[];
    @Prop() enum!: object;

    get values(): SelectOption[] {
        const values = this.value ? (Array.isArray(this.value) ? this.value : [this.value]) : [];
        return values.map((value) => ({
            id: value,
            name: capitalizeWords(value),
        }));
    }

    get options(): SelectOption[] {
        return Object.values(this.enum).map((item) => ({
            id: item,
            name: capitalizeWords(item),
        }));
    }

    get listeners() {
        return {
            ...this.$listeners,
            input: this.input,
        };
    }

    @Emit()
    input(item: SelectOption | SelectOption[] | null): string | string[] | null {
        if (!item) {
            return item;
        }

        if (Array.isArray(item)) {
            return item.map((item) => item.id);
        }

        return item.id;
    }
}
