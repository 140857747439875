import { Action, Module, VuexModule } from "vuex-class-modules";

@Module({ generateMutationSetters: true })
export class SidebarStore extends VuexModule {
    isVisible: boolean = false;
    isCloseable: boolean = false;

    @Action
    open() {
        this.isVisible = true;
        setTimeout(() => (this.isCloseable = true), 0);
    }

    @Action
    close() {
        if (this.isCloseable) {
            this.isVisible = false;
            this.isCloseable = false;
        }
    }
}
