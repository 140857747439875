import { Axios, OrganizationProfileBody, ToJson, UrlFactory } from "@gsx/common";
import {
    OrganizationCreateResponse,
    OrganizationListQuery,
    OrganizationListResponse,
} from "@gsx/common/src/types/http/admin/organization";
import { OrganizationPastProject } from "./OrganizationPastProject";
import { adaptCheckList, adaptOrganization } from "../../utility/entity/organization";
import { CheckListBody } from "@gsx/common/src/types/http/user/organization/checkList";
import {
    adaptOrganizationLog,
    OrganizationLogListQuery,
    OrganizationLogListResponse,
} from "@gsx/common/src/types/http/admin/log";
import { OrganizationFile } from "./OrganizationFile";
import { MergerAcquisitionBody } from "@gsx/common/src/types/http/admin/mergerAcquisition";

export class Organization {
    private readonly urlFactory: UrlFactory;

    public constructor(
        public readonly pastProject: OrganizationPastProject,
        public readonly file: OrganizationFile,
        private readonly axios: Axios,
        urlFactory: UrlFactory,
    ) {
        this.urlFactory = urlFactory.to("/admin/organizations");
    }

    public async list(params: OrganizationListQuery): Promise<OrganizationListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptOrganization),
        };
    }

    public async create(
        body: Partial<ToJson<OrganizationProfileBody>>,
    ): Promise<OrganizationCreateResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.post(url, body);
        return response.data;
    }

    public async get(id: string): Promise<OrganizationProfileBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return adaptOrganization(response.data) as OrganizationProfileBody;
    }

    public async update(id: string, body: Partial<ToJson<OrganizationProfileBody>>): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.put(url, body);
    }

    public async updateLogo(id: string, body: FormData): Promise<void> {
        const url = this.urlFactory.create(`/${id}/logo`);
        await this.axios.put(url, body, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    public async getCheckList(id: string): Promise<CheckListBody> {
        const url = this.urlFactory.create(`/${id}/check-list`);
        const response = await this.axios.get(url);
        return adaptCheckList(response.data);
    }

    public async updateCheckList(id: string, body: Partial<ToJson<CheckListBody>>): Promise<void> {
        const url = this.urlFactory.create(`/${id}/check-list`);
        await this.axios.put(url, body);
    }

    public async getMergerAcquisitions(id: string): Promise<MergerAcquisitionBody[]> {
        const url = this.urlFactory.create(`/${id}/mergerAcquisitions`);
        const response = await this.axios.get(url);
        return response.data.data;
    }

    public async updateMergerAcquisitions(
        id: string,
        body: Partial<Array<ToJson<MergerAcquisitionBody>>>,
    ): Promise<void> {
        const url = this.urlFactory.create(`/${id}/mergerAcquisitions`);
        await this.axios.put(url, body);
    }

    public async listLog(
        id: string,
        params: OrganizationLogListQuery,
    ): Promise<OrganizationLogListResponse> {
        const url = this.urlFactory.create(`/${id}/logs`);
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptOrganizationLog),
        };
    }
}
