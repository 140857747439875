import { Api } from "../../../../common/src/api/Api";
import { injectable } from "inversify";
import { marshal, Nullable, SubmitRequestBody } from "@gsx/common";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import get from "lodash.get";
import { RecaptchaService } from "../../../../common/src/utility/recaptcha/RecaptchaService";
import { SubmitStore } from "../../../../common/src/stores/general/SubmitStore";

@injectable()
export class SubmitRequestStore extends SubmitStore<SubmitRequestBody> {
    readonly data: Nullable<SubmitRequestBody> = {
        firstName: get(this.authStore.user, "firstName", ""),
        lastName: get(this.authStore.user, "lastName", ""),
        phone: get(this.authStore.user, "phone", ""),
        email: get(this.authStore.user, "email", ""),
        affiliatedOrganization: get(this.authStore.user, "organization.organizationName", ""),
        requestReceiver: "gse",
        type: null,
        title: "",
        description: "",
        licenceCount: 0,
        paymentPreference: null,
    };

    constructor(
        private readonly api: Api,
        private readonly authStore: AuthStore,
        private readonly recaptchaService: RecaptchaService,
    ) {
        super();
    }

    protected async request(): Promise<void> {
        const recaptchaToken = await this.recaptchaService.verify("request");
        const body = marshal(this.data);
        await this.api.guest.submitRequest(body, recaptchaToken);
    }
}
