import { Container } from "inversify";
import { Api } from "../api/Api";
import { Store } from "vuex";
import { ServiceTypeStore } from "../stores/ServiceTypeStore";
import { ProductTypeStore } from "../stores/ProductTypeStore";
import { OperatingRegimeTypeStore } from "../stores/OperatingRegimeTypeStore";
import { ApplicationTypeStore } from "../stores/ApplicationTypeStore";
import { FeedbackModalStore } from "../feedback/FeedbackModalStore";
import { SidebarStore } from "../stores/SidebarStore";
import { ApiFactory } from "../api/ApiFactory";
import { AuthStore } from "../stores/AuthStore";
import { NotificationStore } from "../stores/NotificationStore";
import { ExpertiseOptionStore } from "../stores/ExpertiseOptionStore";
import { StandardOptionStore } from "../stores/StandardOptionStore";
import { SubscriptionQuoteModalStore } from "../subscription-quote/SubscriptionQuoteModalStore";
import { IndividualSubscriptionRedirectModalStore } from "../subscription-quote/IndividualSubscriptionRedirectModalStore";
import { AdvertisingQuoteModalStore } from "../advertising-quote/AdvertisingQuoteModalStore";
import VueGtag from "vue-gtag";
import * as vueGtag from "vue-gtag";

export const registerModules = (container: Container, store: Store<any>) => {
    container.bind(Api).toDynamicValue(() => {
        const apiFactory = container.get<ApiFactory>(ApiFactory);
        return apiFactory.create();
    });

    container
        .bind(AuthStore)
        .toDynamicValue(
            () =>
                new AuthStore(container.get(Api), {
                    store,
                    name: "auth",
                }),
        )
        .inSingletonScope();

    container
        .bind(VueGtag)
        // @ts-ignore
        .toConstantValue(vueGtag);

    container.bind(NotificationStore).toSelf().inSingletonScope();

    container.bind(FeedbackModalStore).toSelf().inSingletonScope();

    container.bind(SubscriptionQuoteModalStore).toSelf().inSingletonScope();

    container.bind(IndividualSubscriptionRedirectModalStore).toSelf().inSingletonScope();

    container.bind(AdvertisingQuoteModalStore).toSelf().inSingletonScope();

    container
        .bind(ServiceTypeStore)
        .toDynamicValue(
            () => new ServiceTypeStore(container.get(Api), { store, name: "serviceType" }),
        )
        .inSingletonScope();

    container
        .bind("ReportServiceTypeStore")
        .toDynamicValue(
            () =>
                new ServiceTypeStore(
                    container.get(Api),
                    { store, name: "reportServiceType" },
                    "report",
                ),
        )
        .inSingletonScope();

    container
        .bind("FlashNewsServiceTypeStore")
        .toDynamicValue(
            () =>
                new ServiceTypeStore(
                    container.get(Api),
                    { store, name: "flashnewsServiceType" },
                    "flashnews",
                ),
        )
        .inSingletonScope();

    container
        .bind("OrganizationServiceTypeStore")
        .toDynamicValue(
            () =>
                new ServiceTypeStore(
                    container.get(Api),
                    { store, name: "organizationServiceType" },
                    "organization",
                ),
        )
        .inSingletonScope();

    container
        .bind(ProductTypeStore)
        .toDynamicValue(
            () => new ProductTypeStore(container.get(Api), { store, name: "productType" }),
        )
        .inSingletonScope();

    container
        .bind("ReportProductTypeStore")
        .toDynamicValue(
            () =>
                new ProductTypeStore(
                    container.get(Api),
                    { store, name: "reportProductType" },
                    "report",
                ),
        )
        .inSingletonScope();

    container
        .bind("FlashNewsProductTypeStore")
        .toDynamicValue(
            () =>
                new ProductTypeStore(
                    container.get(Api),
                    { store, name: "flashnewsProductType" },
                    "flashnews",
                ),
        )
        .inSingletonScope();

    container
        .bind("OrganizationProductTypeStore")
        .toDynamicValue(
            () =>
                new ProductTypeStore(
                    container.get(Api),
                    { store, name: "organizationProductType" },
                    "organization",
                ),
        )
        .inSingletonScope();

    container
        .bind(OperatingRegimeTypeStore)
        .toDynamicValue(
            () =>
                new OperatingRegimeTypeStore(container.get(Api), {
                    store,
                    name: "operatingRegimeType",
                }),
        )
        .inSingletonScope();

    container
        .bind("ReportOperatingRegimeTypeStore")
        .toDynamicValue(
            () =>
                new OperatingRegimeTypeStore(
                    container.get(Api),
                    {
                        store,
                        name: "reportsOperatingRegimeType",
                    },
                    "report",
                ),
        )
        .inSingletonScope();

    container
        .bind("FlashNewsOperatingRegimeTypeStore")
        .toDynamicValue(
            () =>
                new OperatingRegimeTypeStore(
                    container.get(Api),
                    {
                        store,
                        name: "flashnewsOperatingRegimeType",
                    },
                    "flashnews",
                ),
        )
        .inSingletonScope();

    container
        .bind("OrganizationOperatingRegimeTypeStore")
        .toDynamicValue(
            () =>
                new OperatingRegimeTypeStore(
                    container.get(Api),
                    {
                        store,
                        name: "organizationOperatingRegimeType",
                    },
                    "organization",
                ),
        )
        .inSingletonScope();

    container
        .bind(ApplicationTypeStore)
        .toDynamicValue(
            () => new ApplicationTypeStore(container.get(Api), { store, name: "applcationType" }),
        )
        .inSingletonScope();

    container
        .bind("ReportApplicationTypeStore")
        .toDynamicValue(
            () =>
                new ApplicationTypeStore(
                    container.get(Api),
                    { store, name: "reportApplcationType" },
                    "report",
                ),
        )
        .inSingletonScope();

    container
        .bind("FlashNewsApplicationTypeStore")
        .toDynamicValue(
            () =>
                new ApplicationTypeStore(
                    container.get(Api),
                    { store, name: "flashNewsApplcationType" },
                    "flashnews",
                ),
        )
        .inSingletonScope();

    container
        .bind("OrganizationApplicationTypeStore")
        .toDynamicValue(
            () =>
                new ApplicationTypeStore(
                    container.get(Api),
                    { store, name: "organizationApplcationType" },
                    "organization",
                ),
        )
        .inSingletonScope();

    container
        .bind(SidebarStore)
        .toDynamicValue(() => new SidebarStore({ store, name: "sidebar" }))
        .inSingletonScope();

    container
        .bind(ExpertiseOptionStore)
        .toDynamicValue(
            () => new ExpertiseOptionStore(container.get(Api), { store, name: "expertiseOption" }),
        )
        .inSingletonScope();

    container
        .bind(StandardOptionStore)
        .toDynamicValue(
            () => new StandardOptionStore(container.get(Api), { store, name: "standardOption" }),
        )
        .inSingletonScope();
};
