import {
    RawUser,
    ToJson,
    UserRepresentation,
    EventType,
    EventTopic,
    isThirdpartyAdmin,
    isAdmin,
    QuiltySubscriptionPlanType,
} from "@gsx/common";
import { adaptDateUtc } from "../../api/adapters";
import { adaptSubscription } from "./subscription";
import { adaptOrganization } from "./organization";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";

export const adaptUser = (data: ToJson<RawUser>): RawUser => ({
    ...data,
    downloadedReports: data.downloadedReports?.map((report) => ({
        ...report,
        date: adaptDateUtc(report.date)!,
    })),
    dateOfBirth: adaptDateUtc(data.dateOfBirth),
    registrationDate: adaptDateUtc(data.registrationDate)!,
    organization: data.organization
        ? (adaptOrganization(data.organization) as RawUser["organization"])
        : undefined,
    subscription: data.subscription ? adaptSubscription(data.subscription) : undefined,
});

export const isQuiltySubscribed = (user: RawUser | null): boolean =>
    Boolean(user && user.quiltySubscription);

export const hasActiveQuiltySubscription = (user: RawUser | null): boolean =>
    isQuiltySubscribed(user) && user!.quiltySubscription!.active;

export const isSubscribed = (user: RawUser | null): boolean => Boolean(user && user.subscription);
export const hasActiveSubscription = isSubscribed;

export const hasAccessToDashboard = (user: RawUser | null): boolean =>
    isSubscribed(user) || isAdmin(user);

export const hasAccessToOrganizationInDashbord = (
    user: RawUser | null,
    organizationId: string,
): boolean => Boolean(user && user.organization?.organizationid === organizationId);

export const hasIndividualSubscription = (user: RawUser | null): boolean =>
    (isSubscribed(user) && user?.subscription?.plan === SubscriptionPlanType.Individual) ||
    isAdmin(user);

export const hasEnterpriseSubscription = (user: RawUser | null): boolean =>
    (isSubscribed(user) && user?.subscription?.plan !== SubscriptionPlanType.Individual) ||
    isAdmin(user);

export const hasQuiltyBasicSubscription = (user: RawUser | null): boolean =>
    (hasActiveQuiltySubscription(user) &&
        user?.quiltySubscription?.plan === QuiltySubscriptionPlanType.Basic) ||
    isThirdpartyAdmin(user) ||
    isAdmin(user);

export const hasQuiltyIntelligenceSubscription = (user: RawUser | null): boolean =>
    (hasActiveQuiltySubscription(user) &&
        user?.quiltySubscription?.plan === QuiltySubscriptionPlanType.Intelligence) ||
    isThirdpartyAdmin(user) ||
    isAdmin(user);

export const hasQuiltyEnterpriseSubscription = (user: RawUser | null): boolean =>
    (hasActiveQuiltySubscription(user) &&
        user?.quiltySubscription?.plan === QuiltySubscriptionPlanType.Enterprise) ||
    isThirdpartyAdmin(user) ||
    isAdmin(user);

export const isFullyRegistered = (user: RawUser | null): boolean => {
    const registeredProperties: Array<keyof RawUser> = [
        "roleInOrganization",
        "streetAddress",
        "country",
        "state",
        "zipcode",
        "city",
    ];

    return !!(
        user && registeredProperties.every((property) => user[property] && user[property] !== "")
    );
};

export const adaptUserRepresentation = (data: any): UserRepresentation => ({
    ...data,
});

export const getEventTypes = (user: RawUser): EventType[] =>
    !isAdmin(user) &&
    (!isSubscribed(user) || user.subscription?.plan === SubscriptionPlanType.Individual)
        ? [EventType.SocialEvent, EventType.Milestone]
        : Object.values(EventType);

export const getEventTopics = (user: RawUser): EventTopic[] =>
    !isAdmin(user) &&
    (!isSubscribed(user) || user.subscription?.plan === SubscriptionPlanType.Individual)
        ? []
        : Object.values(EventTopic);
