import { ListQuery, ListResponse, SortOrder } from "@gsx/common";
import { Action, Mutation, VuexModule } from "vuex-class-modules";

export default abstract class ListStoreVuex<I> extends VuexModule {
    items: I[] | null = null;
    count: number = 0;
    page: number = 1;
    isLoading: boolean = false;
    error: Error | null = null;
    pageSize: number = 10;
    sort?: string = undefined;
    order: SortOrder = SortOrder.Asc;
    pendingRequest: boolean = false;
    moreItems: boolean = true;

    get failed(): boolean {
        return this.error !== null;
    }

    get isLoaded(): boolean {
        return this.items !== null;
    }

    get noRecords(): boolean {
        const items = this.items;
        return Boolean(items && !items.length);
    }

    get pageCount(): number {
        return Math.max(1, Math.ceil(this.count / this.pageSize));
    }

    get queryParams(): ListQuery {
        return {
            skip: String((this.page - 1) * this.pageSize),
            take: String(this.pageSize),
            sort: this.sort || undefined,
            order: this.order,
        };
    }

    @Mutation
    markAsLoading() {
        this.isLoading = true;
    }

    @Mutation
    markAsLoaded(response: ListResponse<I[]>) {
        this.items = response.data;
        this.count = response.count;
        this.error = null;
        this.isLoading = false;
    }

    @Mutation
    markAsFailed(error: Error) {
        this.error = error;
        this.isLoading = false;
    }

    @Mutation
    markPendingRequest() {
        this.pendingRequest = true;
    }

    @Mutation
    unmarkPendingRequest() {
        this.pendingRequest = false;
    }

    @Mutation
    clear() {
        this.items = null;
        this.count = 0;
        this.page = 1;
        this.isLoading = false;
        this.error = null;
        this.pendingRequest = false;
        this.moreItems = true;
    }

    @Action
    async changePage(page: number) {
        this.page = page;
        await this.nextPage();
    }

    @Action
    async infiniteScroll() {
        await this.appendNextPageResults();
    }

    @Action
    async nextPage() {
        if (!this.moreItems) {
            return;
        }

        if (!this.page || !this.items || !this.items.length) {
            return this.reload();
        }

        if (this.isLoading) {
            return;
        }

        this.markAsLoading();

        try {
            const response = await this.fetch();
            this.markAsLoaded(response);
        } catch (e: any) {
            this.markAsFailed(e);
        }
    }

    @Action
    async appendNextPageResults() {
        if (!this.moreItems) {
            return;
        }

        if (!this.page || !this.items || !this.items.length) {
            return this.reload();
        }

        if (this.isLoading) {
            return;
        }

        this.markAsLoading();
        this.page = this.page + 1;

        try {
            const response = await this.fetch();
            this.count = response.count;
            this.items = this.items.concat(response.data);
            this.moreItems = this.items.length < this.count;
            this.error = null;
            this.isLoading = false;
        } catch (e: any) {
            this.markAsFailed(e);
        }
    }

    @Action
    async changePageSize(pageSize: number) {
        this.page = 1;
        this.pageSize = pageSize;
        await this.reload();
    }

    @Action
    async changeSort(sort: string) {
        this.order =
            sort !== this.sort || (sort === this.sort && this.order === SortOrder.Desc)
                ? SortOrder.Asc
                : SortOrder.Desc;

        this.sort = sort;
        await this.reload();
    }

    @Action
    async load(): Promise<void> {
        if (!this.isLoaded) {
            await this.reload();
        }
    }

    @Action
    async reload() {
        if (this.isLoading) {
            return this.markPendingRequest();
        }
        this.clear();

        this.markAsLoading();

        try {
            const response = await this.fetch();
            this.markAsLoaded(response);
        } catch (e: any) {
            this.markAsFailed(e);
        }

        if (this.pendingRequest) {
            this.unmarkPendingRequest();
            await this.reload();
        }
    }

    @Action
    reset(): void {
        this.items = [];
        this.count = 0;
        this.page = 1;
        this.isLoading = false;
        this.error = null;
        this.pageSize = 20;
        this.sort = undefined;
        this.order = SortOrder.Asc;
        this.pendingRequest = false;
        this.moreItems = true;
    }

    protected abstract fetch(): Promise<ListResponse<I[]>>;
}
