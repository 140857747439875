import { Axios, UrlFactory, ToJson } from "@gsx/common";
import { adaptDate } from "../adapters";
import { adaptOrganization } from "../../utility/entity/organization";
import {
    OrganizationListItem,
    OrganizationListResponse,
} from "@gsx/common/src/types/http/dashboard/organization";

export class OrganizationFavorites {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/launchpad/favorites/organizations");
    }

    public async list(): Promise<OrganizationListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url);
        return {
            ...response.data,
            data: response.data.data.map((organization: ToJson<OrganizationListItem>) => ({
                ...adaptOrganization(organization),
                createdAt: adaptDate(organization.createdAt),
            })),
        };
    }

    public async create(organizationId: string): Promise<boolean> {
        const url = this.urlFactory.create(`/${organizationId}`);
        const response = await this.axios.post(url);
        return response.data;
    }

    public async delete(organizationId: string): Promise<void> {
        const url = this.urlFactory.create(`/${organizationId}`);
        await this.axios.delete(url);
    }
}
