
import { Component, Prop, Vue } from "vue-property-decorator";
import countries, { ICity, ICountry, IState } from "../../../../vendor/country-state-city";
import FormInput from "./FormInput.vue";
import { AddressBody, Nullable } from "@gsx/common";
import FormMultiSelect from "./FormMultiSelect.vue";
import { getCity, getCountry, getState } from "../../utility/address";
import { FormErrors } from "../../../../common/src/form/types";

@Component({
    components: { FormMultiSelect, FormInput },
})
export default class AddressFormGroup extends Vue {
    @Prop() values!: Nullable<AddressBody>;
    @Prop({ default: () => ({}) }) errors!: FormErrors<AddressBody>;

    get country(): ICountry | null {
        return getCountry(this.values.country);
    }

    get state(): IState | null {
        return getState(this.country, this.values.state);
    }

    get city(): ICity | null {
        return getCity(this.state, this.values.city);
    }

    get countries(): ICountry[] {
        return countries.getAllCountries();
    }

    get states(): IState[] {
        return this.country ? countries.getStatesOfCountry(this.country.id) : [];
    }

    get cities(): ICity[] {
        return this.state ? countries.getCitiesOfState(this.state.id) : [];
    }

    changeCountry(country: ICountry | null) {
        this.values.country = country ? country.name : null;
    }

    changeState(state: IState | null) {
        this.values.state = state ? state.name : null;
    }

    changeCity(city: ICity | null) {
        this.values.city = city ? city.name : null;
    }
}
