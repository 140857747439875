import { PastProject, ToJson } from "@gsx/common";
import { adaptDateUtc } from "../../api/adapters";

export const adaptPastProject = <T extends Partial<PastProject>>(
    data: ToJson<T>,
): Partial<PastProject> => ({
    ...data,
    dateConducted: adaptDateUtc(data.dateConducted),
    dateCompleted: adaptDateUtc(data.dateCompleted),
});
