
import { Component, Emit, Vue } from "vue-property-decorator";
import RequestForm from "./RequestForm.vue";
import Modal from "../../../../common/src/components/Modal.vue";

@Component({
    components: { Modal, RequestForm },
})
export default class SubmitRequestModal extends Vue {
    @Emit()
    close() {
        //
    }

    @Emit()
    submit() {
        //
    }
}
