import { Action, Mutation, VuexModule } from "vuex-class-modules";

export abstract class ApiStoreVuex<I> extends VuexModule {
    item: I | null = null;
    isLoading: boolean = false;
    error: Error | null = null;

    get failed(): boolean {
        return this.error !== null;
    }

    get isLoaded(): boolean {
        return this.item !== null;
    }

    @Mutation
    markAsLoading() {
        this.isLoading = true;
    }

    @Mutation
    markAsLoaded(response: I) {
        this.item = response;
        this.error = null;
        this.isLoading = false;
    }

    @Mutation
    markAsFailed(error: Error) {
        this.error = error;
        this.isLoading = false;
    }

    @Action
    async load(): Promise<void> {
        if (!this.isLoaded) {
            await this.reload();
        }
    }

    @Action
    async reload(): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.markAsLoading();

        try {
            const response = await this.fetch();
            this.markAsLoaded(response);
        } catch (e: any) {
            this.markAsFailed(e);
        }
    }

    protected abstract fetch(): Promise<I>;
}
