
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { stringToNumber, toDateUtc, toDateUtcString } from "@gsx/common";
import InputField from "./InputField.vue";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";
import { InputType } from "./types";

@Component({
    components: { FormFeedback, FormLabel, InputField },
})
export default class InlineFormInput extends Vue {
    @Prop() label!: string;
    @Prop([String, Date, Number]) value!: string;
    @Prop({ default: InputType.Text }) type!: InputType;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: false }) readonly!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;
    @Prop() placeholder?: string;
    @Prop({ default: "text-muted" }) labelClass?: string;
    @Ref("input") readonly input!: HTMLInputElement;
    focused: boolean = false;

    get inputType(): InputType {
        if (this.type === InputType.Currency) {
            return InputType.Number;
        }

        if (this.type === InputType.Year) {
            return InputType.Number;
        }

        return this.type;
    }

    get inputValue(): string | null {
        return this.marshalValue(this.value);
    }

    get displayValue(): string | null {
        const value = this.marshalValue(this.value);

        if (value === null) {
            return null;
        }

        if (this.type === InputType.Currency) {
            const format = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
            return format.format(Number(value));
        }

        if (this.type === InputType.Number) {
            const format = new Intl.NumberFormat("en-US");
            return format.format(Number(value));
        }

        return value;
    }

    onChange(event: any) {
        if (!this.readonly) {
            const value = event.target.value;
            this.$emit("input", this.unmarshalValue(value));
        }
    }

    focus() {
        this.focused = true;
        this.$nextTick(() => this.input.focus());
    }

    unfocus() {
        this.focused = false;
    }

    marshalValue(value: any): string | null {
        if (value === null || value === undefined) {
            return null;
        }

        if (this.inputType === InputType.Date) {
            return toDateUtcString(value);
        }

        if (this.inputType === InputType.Number) {
            return String(value);
        }

        return value;
    }

    unmarshalValue(value: string | null): any {
        if (this.inputType === InputType.Date) {
            return toDateUtc(value);
        }

        if (this.inputType === InputType.Number) {
            return stringToNumber(value);
        }

        return value;
    }
}
