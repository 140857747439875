import { SortOrder } from "@gsx/common";
import ListStoreVuex from "common/src/stores/general/ListStoreVuex";
import { CollectionStore } from "../../../common/src/stores/CollectionStore";

interface Column {
    id?: string;
}

export interface EnrichedColumn {
    customClass: string;
}

const getColumnClass = (collectionStore: CollectionStore<any>, id?: string): string => {
    if (!id) {
        return "";
    }

    if (id !== collectionStore.sort) {
        return "sorting";
    }

    if (collectionStore.order === SortOrder.Asc) {
        return "sorting_asc";
    }

    return "sorting_desc";
};

export const enrichedColumns = <C extends Column>(
    collectionStore: CollectionStore<any>,
    columns: ReadonlyArray<C>,
): ReadonlyArray<C & EnrichedColumn> =>
    columns.map((column) => ({
        ...column,
        customClass: getColumnClass(collectionStore, column.id),
    }));

const getListColumnClass = (collectionStore: ListStoreVuex<any>, id?: string): string => {
    if (!id) {
        return "";
    }

    if (id !== collectionStore.sort) {
        return "sorting";
    }

    if (collectionStore.order === SortOrder.Asc) {
        return "sorting_asc";
    }

    return "sorting_desc";
};

export const enrichedListColumns = <C extends Column>(
    collectionStore: ListStoreVuex<any>,
    columns: ReadonlyArray<C>,
): ReadonlyArray<C & EnrichedColumn> =>
    columns.map((column) => ({
        ...column,
        customClass: getListColumnClass(collectionStore, column.id),
    }));
