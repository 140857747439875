
import { Component, Vue, Inject } from "vue-property-decorator";
import ActionButton from "./ActionButton.vue";
import { AuthStore } from "../stores/AuthStore";
import { Container } from "inversify";
import { RawUser } from "@gsx/common";
import { hasActiveSubscription } from "../utility/entity/user";
import { IndividualSubscriptionRedirectModalStore } from "../../../common/src/subscription-quote/IndividualSubscriptionRedirectModalStore";

@Component({
    components: {
        ActionButton,
    },
})
export default class TierProgressBar extends Vue {
    @Inject("container") readonly container!: Container;
    readonly authStore: AuthStore = this.container.get(AuthStore);
    readonly individualSubscriptionRedirectModalStore: IndividualSubscriptionRedirectModalStore =
        this.container.get(IndividualSubscriptionRedirectModalStore);

    hasRegisteredProperty(property: keyof RawUser) {
        const user = this.authStore.user;
        if (user) {
            return user[property] && user[property] !== "";
        }
        return false;
    }

    get percentRegistered(): number {
        let percent = 0;
        const percentGrowth = Math.ceil(100 / 6);
        const user = this.authStore.user;
        const registeredProperties: (keyof RawUser)[] = [
            "roleInOrganization",
            "streetAddress",
            "country",
            "state",
            "zipcode",
            "city",
        ];
        if (user) {
            for (let index of registeredProperties) {
                if (this.hasRegisteredProperty(index)) {
                    percent += percentGrowth;
                }
            }
        }
        return Math.min(percent, 100);
    }

    get isSubscribed(): boolean {
        return hasActiveSubscription(this.authStore.user);
    }

    get isEnterprise(): boolean {
        return false;
    }

    openSubscriptionQuoteModal() {
        this.individualSubscriptionRedirectModalStore.open();
    }
}
