import slugify from "slugify";
import { SelectOption } from "./types";
import isObject from "lodash.isobject";

export const createIdFromLabel = (label: string): string =>
    `form-${slugify(label, { lower: true })}`;

export const convertToSelectOptions = (
    value?: string | string[] | SelectOption | SelectOption[],
): SelectOption[] => {
    if (!value) {
        return [];
    }

    const values = Array.isArray(value) ? value : [value];

    return values.map((item) => {
        if (isObject(item)) {
            return item;
        }

        return {
            id: item,
            name: item,
        };
    });
};
