import get from "lodash.get";
import { isAxiosError } from "../../../common/src/utility/http";

export const getErrorHttpStatus = (error: Error | null): number | undefined =>
    isAxiosError(error) ? get(error.response, "status") : undefined;

export const compare = (a: any, b: any, asc: boolean = true): number => {
    if (a < b) {
        return asc ? -1 : 1;
    }

    if (a > b) {
        return asc ? 1 : -1;
    }

    return 0;
};
