import { AxiosError } from "axios";
import { Dict } from "@gsx/common";
import { UNPROCESSABLE_ENTITY } from "http-status-codes";
import { isAxiosError } from "../utility/http";

export const focusOnFirstInvalidInput = (): void => {
    const item = document.querySelector<HTMLInputElement>(".is-invalid");
    if (item) {
        item.focus();
        item.scrollIntoView();
    }
};

const getFormErrors = (error: AxiosError): Dict<string> =>
    error.response &&
    error.response.status === UNPROCESSABLE_ENTITY &&
    error.response.data &&
    error.response.data.errors
        ? { ...error.response.data.errors }
        : {};

export const formErrors = (e: any): Dict<string> => {
    const errors = isAxiosError(e) ? getFormErrors(e) : {};

    for (const [key, value] of Object.entries(errors)) {
        // When updating multi select user can somehow provide invalid data
        // JSON schema will return error under such a key: "manufactureInternal/{number}"
        // We want to remove number from the end of string and assing error under "manufactureInternal" key.
        const listMatch = /(\w+)\/\d+/.exec(key);
        if (listMatch) {
            const path = listMatch[1];

            if (!(path in errors)) {
                errors[path] = value;
            }
        }
    }

    return errors;
};
