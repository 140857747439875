import { Axios, UrlFactory } from "@gsx/common";
import { OrganizationFileItem } from "@gsx/common/src/types/models/organizationFile";

export class OrganizationFile {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/organizations");
    }

    public async upload(organizationId: string, files: File[]): Promise<void> {
        const url = this.urlFactory.create(`/${organizationId}/files`);

        const formData = new FormData();
        for (const file of files) {
            formData.append("files", file);
        }

        await this.axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    public async remove(organizationId: string, fileId: string): Promise<void> {
        const url = this.urlFactory.create(`/${organizationId}/files/${fileId}`);
        await this.axios.delete(url);
    }

    public async list(organizationId: string): Promise<OrganizationFileItem[]> {
        const url = this.urlFactory.create(`/${organizationId}/files`);
        const response = await this.axios.get(url);
        return response.data.data;
    }
}
