import { Axios, UrlFactory } from "@gsx/common";
import {
    QuiltyResourceBody,
    QuiltyResourceListItem,
    QuiltyResourceListQuery,
    QuiltyResourceListResponse,
} from "@gsx/common/src/types/http/dashboard/quiltyResource";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): QuiltyResourceListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class QuiltyResource {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/organizations");
    }

    public async list(params: QuiltyResourceListQuery): Promise<QuiltyResourceListResponse> {
        const url = this.urlFactory.create(`/${params.organizationId}/quiltyResources`);
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async get(id: string): Promise<QuiltyResourceBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as QuiltyResourceBody;
    }
}
