import { Api } from "../../../../../common/src/api/Api";
import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";
import { RequestAffiliationBody, UserRoleInOrganization } from "@gsx/common";

@injectable()
export class RequestAffiliationStore extends SubmitStore<RequestAffiliationBody> {
    data: RequestAffiliationBody = {
        organizationName: "",
        roleInOrganization: UserRoleInOrganization.Administration,
    };

    constructor(private readonly api: Api) {
        super();
    }

    @boundMethod
    protected async request(): Promise<void> {
        await this.api.user.affiliateUserWithOrganization(this.data);
    }
}
