import { Api } from "../../../../../common/src/api/Api";
import { marshal, Nullable, RawUser, unmarshal, UpdateUserProfileBody } from "@gsx/common";
import { injectable } from "inversify";
import { AuthStore } from "../../../../../common/src/stores/AuthStore";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";

@injectable()
export class ProfileStore extends SubmitStore<UpdateUserProfileBody> {
    readonly data: Nullable<UpdateUserProfileBody> = {
        affiliation: unmarshal(this.user.affiliation),
        citizenship: unmarshal(this.user.citizenship),
        city: unmarshal(this.user.city),
        country: unmarshal(this.user.country),
        dateOfBirth: unmarshal(this.user.dateOfBirth),
        expertise: unmarshal(this.user.expertise),
        firstName: unmarshal(this.user.firstName),
        lastName: unmarshal(this.user.lastName),
        newsletterEmail: !!this.user.newsletterEmail,
        quiltyMarketMonitorEmails: !!this.user.quiltyMarketMonitorEmails,
        quiltyNewsletterEmails: !!this.user.quiltyNewsletterEmails,
        quiltyReportNotificaitonEmails: !!this.user.quiltyReportNotificaitonEmails,
        phone: unmarshal(this.user.phone),
        pictureProfile: unmarshal(this.user.pictureProfile),
        professionalInterests: unmarshal(this.user.professionalInterests),
        sex: unmarshal(this.user.sex),
        state: unmarshal(this.user.state),
        streetAddress: unmarshal(this.user.streetAddress),
        termsAccepted: unmarshal(this.user.termsAccepted),
        zipcode: unmarshal(this.user.zipcode),
        roleInOrganization: unmarshal(this.user.roleInOrganization),
    };

    constructor(private readonly api: Api, private readonly authStore: AuthStore) {
        super();
    }

    private get user(): RawUser {
        return this.authStore.user!;
    }

    protected async request(): Promise<void> {
        const body = marshal(this.data);
        await this.api.user.updateProfile(body);
    }
}
