import { Api } from "../../../../../common/src/api/Api";
import ListStoreVuex from "../../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";
import {
    UserActivityLogListItem,
    UserActivityLogListResponse,
} from "@gsx/common/src/types/http/admin/userActivityLog";

@Module({ generateMutationSetters: true })
export class UserActivityLogsListStore extends ListStoreVuex<UserActivityLogListItem> {
    sort: keyof UserActivityLogListItem = "createdAt";
    query: string = "";

    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch(): Promise<UserActivityLogListResponse> {
        return this.api.admin.user.listActivityLogs({
            ...this.queryParams,
            query: this.query,
        });
    }
}
