import Swal from "sweetalert2";

export const Confirm = Swal.mixin({
    title: "Are you sure?",
    text: "You will not be able to undo the action!",
    type: "warning",
    showCancelButton: true,
});

export async function DeleteUser(email: string) {
    return Swal.fire({
        title: "Are you sure you want to remove the license?",
        text: email,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete User",
    });
}

export async function CreateUser(email: string) {
    return Swal.fire({
        title: "Are you sure you want to add the user?",
        text: email,
        type: "success",
        showCancelButton: true,
        confirmButtonText: "Add User",
    });
}

export async function AskDownloadReport(reportName: string) {
    return Swal.fire({
        title: `Viewing this report will use one of your two quarterly credits`,
        text: `${reportName}`,
        type: "success",
        showCancelButton: true,
        confirmButtonText: "Use Credit",
    });
}

export async function AskPurchaseReport(reportName: string, reportPrice: string = "0.00") {
    return Swal.fire({
        title: `Purchase report?`,
        text: `${reportName} ($${parseFloat(reportPrice).toFixed(2)})`,
        type: "success",
        showCancelButton: true,
        confirmButtonText: `Go to checkout`,
    });
}
