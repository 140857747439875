import { Axios, UrlFactory } from "@gsx/common";
import {
    ReportBody,
    ReportListItem,
    ReportListQuery,
    ReportListResponse,
} from "@gsx/common/src/types/http/dashboard/report";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): ReportListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class Report {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/reports");
    }

    public async list(params: ReportListQuery): Promise<ReportListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async get(id: string): Promise<ReportBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as ReportBody;
    }

    public async downloadReport(id: string): Promise<string> {
        const url = this.urlFactory.create(`/${id}/pdf`);
        const response = await this.axios.get(url);
        return response.data as string;
    }
}
