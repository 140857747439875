
import { Component, Prop, Vue } from "vue-property-decorator";
import { createIdFromLabel } from "./utils";
import { randomString } from "@gsx/common";

@Component
export default class FormCheckbox extends Vue {
    @Prop() value!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop() label?: string;

    get id(): string {
        return this.label ? createIdFromLabel(this.label) : randomString();
    }

    onChange(value: any) {
        this.$emit("input", value.target.checked);
    }
}
