import { Axios, UrlFactory } from "@gsx/common";
import {
    CreateSubscriptionSesssionBody,
    CreateSesssionRespone,
    CreateProductSesssionBody,
} from "@gsx/common/src/types/http/payment/session";

export class Payment {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/payment");
    }

    public async createSubscriptionSession(
        body: CreateSubscriptionSesssionBody,
    ): Promise<CreateSesssionRespone> {
        const url = this.urlFactory.create("/session/subscription");
        const response = await this.axios.post(url, body);
        return response.data;
    }

    public async createProductSession(
        body: CreateProductSesssionBody,
    ): Promise<CreateSesssionRespone> {
        const url = this.urlFactory.create("/session/product");
        const response = await this.axios.post(url, body);
        return response.data;
    }

    public async creatReportSession(
        body: CreateProductSesssionBody,
        reportId: string,
    ): Promise<CreateSesssionRespone> {
        const url = this.urlFactory.create(`/session/report/${reportId}`);
        const response = await this.axios.post(url, body);
        return response.data;
    }
}
