import { injectable } from "inversify";
import {
    hasOrganizationAccess,
    hasRole,
    isAdmin,
    isThirdpartyAdmin,
    sleep,
    UserRole,
} from "@gsx/common";
import { boundMethod } from "autobind-decorator";
import { NavigationGuard } from "vue-router";
import { AuthStore } from "./stores/AuthStore";
import { NotificationStore } from "./stores/NotificationStore";
import {
    hasAccessToDashboard,
    hasAccessToOrganizationInDashbord,
    hasEnterpriseSubscription,
    isQuiltySubscribed,
} from "./utility/entity/user";

@injectable()
export class AuthGate {
    constructor(
        private readonly authStore: AuthStore,
        private readonly notificationStore: NotificationStore,
    ) {
        //
    }

    @boundMethod
    requireAuthorizedGuard(roles: UserRole[] = []): NavigationGuard {
        return async (_to, from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (!this.authStore.authorized) {
                this.notificationStore.warning("You need to be authorized.");
                next("/signin");
            } else if (
                roles.length &&
                roles.every((role) => !hasRole(this.authStore.user!, role))
            ) {
                if (isThirdpartyAdmin(this.authStore.user) && _to.path.startsWith("/admin/")) {
                    next(_to.path.replace("/admin/", "/quiltyAdmin/"));
                    return;
                }

                this.notificationStore.warning("You do not have enough permissions.");
                next(from as any);
            } else {
                next();
            }
        };
    }

    @boundMethod
    requireUnauthorizedGuard(): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (this.authStore.authorized) {
                window.location.href = "dashboard";
            } else {
                next();
            }
        };
    }

    requireOrganizationAccess(access: string): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (hasOrganizationAccess(this.authStore.user, access, _to.params.organization)) {
                next();
                return;
            }

            window.location.href = this.authStore.authorized ? "/dashboard" : "/signin";
        };
    }

    @boundMethod
    requireMembershipGuard(): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (hasAccessToDashboard(this.authStore.user)) {
                next();
                return;
            }

            if (
                _to.matched[0].path === "/organizations/:organization" &&
                hasAccessToOrganizationInDashbord(this.authStore.user, _to.params.organization)
            ) {
                next();
                return;
            }

            window.location.href = this.authStore.authorized ? "/dashboard/member" : "/signin";
        };
    }

    @boundMethod
    requireEnterpriseMembershipGuard(): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (hasEnterpriseSubscription(this.authStore.user)) {
                next();
                return;
            }

            window.location.href = this.authStore.authorized ? "/dashboard/member" : "/signin";
        };
    }

    @boundMethod
    requireRegisteredGuard(): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (this.authStore.authorized) {
                next();
                return;
            }

            if (
                _to.matched[0].path === "/organizations/:organization" &&
                hasAccessToOrganizationInDashbord(this.authStore.user, _to.params.organization)
            ) {
                next();
                return;
            }

            window.location.href = "/signin";
        };
    }

    @boundMethod
    redirectInitialPath(): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (hasAccessToDashboard(this.authStore.user)) {
                next();
            } else {
                next("conferences");
            }
            return;
        };
    }

    @boundMethod
    requireQuiltyMembershipGuard(): NavigationGuard {
        return async (_to, _from, next) => {
            await this.waitUntilAuthIsLoaded();

            if (
                isQuiltySubscribed(this.authStore.user) ||
                isAdmin(this.authStore.user) ||
                isThirdpartyAdmin(this.authStore.user)
            ) {
                next();
                return;
            }

            window.location.href = this.authStore.authorized ? "/dashboard/member" : "/signin";
        };
    }

    // TODO I'm sure it can be implemented better
    private async waitUntilAuthIsLoaded(): Promise<void> {
        while (!this.authStore.loaded) {
            await sleep(10);
        }
    }
}
