import { QuiltySubscriptionPlan, RawUser } from "@gsx/common";
import { SubscriptionPlan } from "@gsx/common/src/types/models/subscription";
import {
    getQuiltySubscriptionPlan,
    getSubscriptionPlan,
} from "@gsx/common/src/utils/subscriptionUtils";

export const getUserSubscriptionPlan = (user: RawUser | null): SubscriptionPlan | null =>
    user && user.subscription ? getSubscriptionPlan(user.subscription.plan) : null;

export const getUserQuiltySubscriptionPlan = (
    user: RawUser | null,
): QuiltySubscriptionPlan | null =>
    user && user.quiltySubscription
        ? getQuiltySubscriptionPlan(user.quiltySubscription.plan)
        : null;
