import { AnalyticFilter, Axios, UrlFactory } from "@gsx/common";

export class AnalyticFavorites {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/launchpad/favorites/analytics");
    }

    public async create(analyticId: string, filters?: AnalyticFilter): Promise<boolean> {
        const url = this.urlFactory.create(`/${analyticId}`);
        const response = await this.axios.post(url, { filters });
        return response.data;
    }

    public async delete(analyticId: string): Promise<void> {
        const url = this.urlFactory.create(`/${analyticId}`);
        await this.axios.delete(url);
    }
}
