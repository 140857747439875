import { Axios, UrlFactory } from "@gsx/common";
import {
    MergerAcquisitionListQuery,
    OrganizationMergerAcquisitionListResponse,
} from "@gsx/common/src/types/http/dashboard/mergerAcquisition";

export class OrganizationMergerAcquisitions {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/organizationMergerAcquisitions");
    }

    public async list(
        params: MergerAcquisitionListQuery,
    ): Promise<OrganizationMergerAcquisitionListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data,
        };
    }
}
