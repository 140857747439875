import { UserFavorites } from "./UserFavorites";
import { UserActivity } from "./UserActivity";
import { UserVisitedOrganizations } from "./UserVisitedOrganizations";

export class Launchpad {
    public constructor(
        public readonly userFavorites: UserFavorites,
        public readonly userActivity: UserActivity,
        public readonly userVisitedOrganizations: UserVisitedOrganizations,
    ) {
        //
    }
}
