import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";

@injectable()
export class SubscriptionQuoteModalStore {
    isVisible: boolean = false;
    isGsx: boolean = true;

    @boundMethod
    open(subscription?: string) {
        this.isGsx = !(subscription && subscription === "quilty");
        this.isVisible = true;
    }

    @boundMethod
    close() {
        this.isVisible = false;
    }
}
