
import { Component, Prop, Vue } from "vue-property-decorator";
import { createIdFromLabel } from "./utils";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";

@Component({
    components: { FormFeedback, FormLabel },
})
export default class FormSelect extends Vue {
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop({ default: false }) required!: boolean;
    @Prop() placeholder?: string;
    @Prop() error?: string;
    @Prop() help?: string;
    @Prop() customClass?: string;

    get id(): string {
        return createIdFromLabel(this.label);
    }
}
