import { Action, Mutation, VuexModule } from "vuex-class-modules";

export abstract class DestroyStoreVuex extends VuexModule {
    isLoading: boolean = false;
    error: Error | null = null;

    get failed(): boolean {
        return this.error !== null;
    }

    @Mutation
    markAsLoading() {
        this.isLoading = true;
    }

    @Mutation
    markAsLoaded() {
        this.error = null;
        this.isLoading = false;
    }

    @Mutation
    markAsFailed(error: Error) {
        this.error = error;
        this.isLoading = false;
    }

    @Action
    async delete(id: string): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.markAsLoading();

        try {
            await this.request(id);
            this.markAsLoaded();
        } catch (e: any) {
            this.markAsFailed(e);
        }
    }

    protected abstract request(id: string): Promise<void>;
}
