import { Axios, UrlFactory } from "@gsx/common";
import {
    UserActivityLogListQuery,
    UserActivityLogListResponse,
} from "@gsx/common/src/types/http/dashboard/userActivityLog";
import { adaptUserRepresentation } from "../../utility/entity/user";

export class UserActivity {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/launchpad");
    }

    public async listActivityLogs(
        params?: UserActivityLogListQuery,
    ): Promise<UserActivityLogListResponse> {
        const url = this.urlFactory.create("/userActivity");
        document.cookie = `timezoneOffset=${new Date().getTimezoneOffset()}`;
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptUserRepresentation),
        };
    }
}
