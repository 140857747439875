import { Axios, UrlFactory, ToJson } from "@gsx/common";
import {
    ConferenceListResponse,
    ConferenceListItem,
} from "@gsx/common/src/types/http/dashboard/conference";
import { adaptDateUtc } from "../adapters";
import { adaptConference } from "../../utility/entity/conference";

export class ConferenceFavorites {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/launchpad/favorites/conferences");
    }

    public async list(): Promise<ConferenceListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url);
        return {
            ...response.data,
            data: response.data.data.map((conference: ToJson<ConferenceListItem>) => ({
                ...adaptConference(conference),
                createdAt: adaptDateUtc(conference.createdAt),
            })),
        };
    }

    public async create(conferenceId: string): Promise<boolean> {
        const url = this.urlFactory.create(`/${conferenceId}`);
        const response = await this.axios.post(url);
        return response.data;
    }

    public async delete(conferenceId: string): Promise<void> {
        const url = this.urlFactory.create(`/${conferenceId}`);
        await this.axios.delete(url);
    }
}
