
import { Component, Emit, Inject, Vue } from "vue-property-decorator";
import { NotificationStore } from "../../../../common/src/stores/NotificationStore";
import { Container } from "inversify";
import { SubmitRequestStore } from "../../stores/request-form/SubmitRequestStore";
import { RequestType } from "@gsx/common";
import { focusOnFirstInvalidInput } from "../../../../common/src/form/utils";
import FormInput from "../form/FormInput.vue";
import InlineFormInput from "../form/InlineFormInput.vue";
import FormTel from "../form/FormTel.vue";
import FormSelect from "../form/FormSelect.vue";
import FormTextarea from "../form/FormTextarea.vue";
import { AuthStore } from "../../../../common/src/stores/AuthStore";
import RecaptchaDisclosure from "../recaptcha/RecaptchaDisclosure.vue";
import ActionButton from "../../../../common/src/components/ActionButton.vue";

@Component({
    components: {
        RecaptchaDisclosure,
        ActionButton,
        FormTextarea,
        FormSelect,
        FormTel,
        FormInput,
        InlineFormInput,
    },
})
export default class RequestForm extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly submitRequestStore: SubmitRequestStore = this.container.get(SubmitRequestStore);
    readonly authStore: AuthStore = this.container.get(AuthStore);

    get values() {
        return this.submitRequestStore.data;
    }

    get errors() {
        return this.submitRequestStore.formErrors;
    }

    get requestTypes(): RequestType[] {
        return Object.values(RequestType).filter((type) => type !== RequestType.ThirdParty);
    }

    get autofocusTitle(): boolean {
        return !!this.values.firstName;
    }

    @Emit()
    submit() {
        //
    }

    created() {
        this.submitRequestStore.data.licenceCount = 1;
    }

    async submitRequest() {
        await this.submitRequestStore.submit();

        if (this.submitRequestStore.failed) {
            this.notificationStore.warning("Submitting request has failed.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success("You have submitted the request successfully.");
            this.submit();
        }
    }
}
