import { Axios, UrlFactory } from "@gsx/common";
import {
    OrganizationPastProjectListItem,
    SimilarOrganization,
} from "@gsx/common/src/types/http/dashboard/organization";

export class OrganizationResources {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/organizations");
    }

    public async getSimilarOrganizations(id: string): Promise<SimilarOrganization[]> {
        const url = this.urlFactory.create(`/${id}/similarOrganizations`);
        const response = await this.axios.get(url);
        return response.data;
    }

    public async getProjects(id: string): Promise<OrganizationPastProjectListItem[]> {
        const url = this.urlFactory.create(`/${id}/projects`);
        const response = await this.axios.get(url);
        return response.data;
    }
}
