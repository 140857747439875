export interface SelectOption {
    id: string;
    name: string;
    new?: boolean;
    secondaryText?: string;
}

export type MultiSelectOption = string | string[] | SelectOption | SelectOption[];

export enum InputType {
    Currency = "currency",
    Date = "date",
    Number = "number",
    Text = "text",
    Year = "year",
}
