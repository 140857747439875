import { Axios, PastProjectItem, ToJson, UrlFactory } from "@gsx/common";
import { PastProjectListResponse } from "@gsx/common/src/types/http/user/organization/pastProject";
import { adaptPastProject } from "../../utility/entity/pastProject";

export class PastProject {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/user/organization/past-projects");
    }

    public async list(): Promise<PastProjectListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url);
        return {
            ...response.data,
            data: response.data.data.map(adaptPastProject),
        };
    }

    public async create(body: Partial<ToJson<PastProjectItem>>): Promise<void> {
        const url = this.urlFactory.create("/");
        await this.axios.post(url, body);
    }

    public async get(id: string): Promise<PastProjectItem> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return adaptPastProject(response.data) as PastProjectItem;
    }

    public async update(id: string, body: Partial<ToJson<PastProjectItem>>): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.put(url, body);
    }

    public async delete(id: string): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.delete(url);
    }
}
