import { boundMethod } from "autobind-decorator";
import { formErrors } from "../../form/utils";
import { FormErrors } from "../../form/types";

export abstract class SubmitStore<T> {
    isLoading: boolean = false;
    error: Error | null = null;

    get failed(): boolean {
        return this.error !== null;
    }

    get formErrors(): FormErrors<T> {
        return formErrors(this.error) as FormErrors<T>;
    }

    @boundMethod
    async submit(): Promise<void> {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;

        try {
            await this.request();
            this.error = null;
        } catch (e: any) {
            this.error = e;
        } finally {
            this.isLoading = false;
        }
    }

    protected abstract request(): Promise<void>;
}
