export const isProduction = (): boolean => process.env.NODE_ENV === "production";

export const ignore = () => {
    //
};

// https://github.com/darkskyapp/string-hash
export const hashCode = (text: string): number => {
    let hash = 5381;
    let i = text.length;

    while (i) {
        // tslint:disable-next-line:no-bitwise
        hash = (hash * 33) ^ text.charCodeAt((i -= 1));
    }

    // tslint:disable-next-line:no-bitwise
    return hash >>> 0;
};
