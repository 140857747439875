import { Axios, UrlFactory } from "@gsx/common";
import {
    MergerAcquisitionListItem,
    MergerAcquisitionListQuery,
    MergerAcquisitionListResponse,
} from "@gsx/common/src/types/http/dashboard/mergerAcquisition";

export class MergerAcquisitions {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/mergerAcquisitions");
    }

    public async list(params: MergerAcquisitionListQuery): Promise<MergerAcquisitionListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map((merger: MergerAcquisitionListItem) => ({
                ...merger,
                announceDate: merger.announceDate ? new Date(merger.announceDate) : null,
            })),
        };
    }
}
