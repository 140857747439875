import { Axios, UrlFactory } from "@gsx/common";
import { OrganizationListResponse } from "@gsx/common/src/types/http/guest/organization";

export class Organization {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/organizations");
    }

    public async list(): Promise<OrganizationListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url);
        return response.data;
    }
}
