import { Axios, UrlFactory } from "@gsx/common";

type OrderListItem = any;
type OrderListResponse = any;
type OrderListQuery = any;

const adaptOrder = (data: any): OrderListItem => ({
    ...data,
});

export class Order {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/orders");
    }

    public async list(params: OrderListQuery): Promise<OrderListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptOrder),
        };
    }
}
