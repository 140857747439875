
import { Component, Inject, Vue } from "vue-property-decorator";
import { Container } from "inversify";
import { FeedbackModalStore, FeedbackModalType } from "./FeedbackModalStore";

@Component
export default class RecommendOrganization extends Vue {
    @Inject("container") readonly container!: Container;
    feedbackModalStore: FeedbackModalStore = this.container.get(FeedbackModalStore);

    get FeedbackModalType() {
        return FeedbackModalType;
    }
}
