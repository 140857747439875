import { Axios, UrlFactory } from "@gsx/common";

export class Subscription {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/subscription");
    }

    public async delete(id: string): Promise<void> {
        const url = this.urlFactory.create(`/${id}`);
        await this.axios.delete(url);
    }
}
