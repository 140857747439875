import { Axios, UrlFactory } from "@gsx/common";
import {
    FlashNewsBody,
    FlashNewsListItem,
    FlashNewsListQuery,
    FlashNewsListResponse,
} from "@gsx/common/src/types/http/dashboard/flashNews";
import { adaptDate } from "../adapters";

const adaptRequest = (data: any): FlashNewsListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class FlashNews {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/dashboard/flashNews");
    }

    public async list(params: FlashNewsListQuery): Promise<FlashNewsListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptRequest),
        };
    }

    public async get(id: string): Promise<FlashNewsBody> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data as FlashNewsBody;
    }

    public async toggle(id: string): Promise<FlashNewsBody> {
        const url = this.urlFactory.create(`/${id}/like`);
        const response = await this.axios.post(url);
        return response.data as FlashNewsBody;
    }
}
