import { Axios, ToJson, UrlFactory } from "@gsx/common";
import {
    OrganizationItem,
    OrganizationListItem,
    OrganizationListResponse,
} from "@gsx/common/src/types/http/dashboard/organization";
import { adaptOrganization } from "../../utility/entity/organization";
import { adaptDate } from "../adapters";
import { OrganizationResources } from "./OrganizationResources";

export class Organization {
    private readonly urlFactory: UrlFactory;

    public constructor(
        public readonly resources: OrganizationResources,
        private readonly axios: Axios,
        urlFactory: UrlFactory,
    ) {
        this.urlFactory = urlFactory.to("/dashboard/organizations");
    }

    public async list(params: any): Promise<OrganizationListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map((organization: ToJson<OrganizationListItem>) => ({
                ...adaptOrganization(organization),
                createdAt: adaptDate(organization.createdAt),
            })),
        };
    }

    public async get(id: string): Promise<OrganizationItem> {
        const url = this.urlFactory.create(`/${id}`);
        const response = await this.axios.get(url);
        return response.data;
    }
}
