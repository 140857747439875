
import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import InputField from "./InputField.vue";
import Multiselect from "vue-multiselect";
import { MultiSelectOption, SelectOption } from "./types";
import { convertToSelectOptions } from "./utils";
import FormLabel from "./FormLabel.vue";
import FormFeedback from "./FormFeedback.vue";

@Component({
    components: { FormFeedback, FormLabel, InputField, Multiselect },
    inheritAttrs: false,
})
export default class FormMultiSelect extends Vue {
    @Prop() label?: string;
    @Prop([String, Array, Object]) value!: MultiSelectOption;
    @Prop({ type: Array, default: () => [] }) options!: string[] | SelectOption[];
    @Prop({ default: false }) multiple!: boolean;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: "" }) placeholder!: string;
    @Prop() customClass?: string;
    @Prop() error?: string;
    @Prop() help?: string;
    @Prop({ default: "No elements found. Consider changing the search query." })
    noResultText?: string;
    @Ref("select") readonly select!: Multiselect;

    get formattedValue(): SelectOption[] {
        return convertToSelectOptions(this.value);
    }

    get formattedOptions(): SelectOption[] {
        const options = convertToSelectOptions(this.options);
        const map = new Map(
            [...this.formattedValue, ...options].map((option) => [option.id, option]),
        );
        return [...map.values()];
    }

    get listeners() {
        return {
            ...this.$listeners,
            input: this.input,
            tag: this.addItem,
        };
    }

    @Emit()
    input(value: MultiSelectOption) {
        return value;
    }

    focus() {
        // @ts-ignore
        this.$nextTick(() => this.select.activate());
    }

    addItem(text: string) {
        const item: SelectOption = {
            id: text,
            name: text,
            new: true,
        };

        if (this.multiple) {
            this.input([...this.formattedValue, item]);
        } else {
            this.input(item);
        }
    }
}
