import { TreeStructure, TreeType } from "@gsx/common";
import { Module, RegisterOptions } from "vuex-class-modules";
import { Api } from "../api/Api";
import { ApiStoreVuex } from "./general/ApiStoreVuex";
import { flattenTree } from "../utility/treeUtils";

@Module()
export class OperatingRegimeTypeStore extends ApiStoreVuex<TreeStructure[]> {
    constructor(
        private readonly api: Api,
        options: RegisterOptions,
        private readonly type?: string,
    ) {
        super(options);
    }

    get idLabelMapping(): ReadonlyMap<string, string> {
        return new Map(
            this.item ? flattenTree(this.item).map((node) => [node.id, node.label]) : [],
        );
    }

    get dictionary(): ReadonlyMap<string, TreeStructure> {
        return new Map(this.item ? flattenTree(this.item).map((node) => [node.id, node]) : []);
    }

    protected async fetch() {
        return this.api.guest.getTree(TreeType.OperatingRegime, this.type);
    }
}
