import { injectable } from "inversify";
import { boundMethod } from "autobind-decorator";

export enum FeedbackModalType {
    Feedback = "feedback",
    RecommendOrganization = "recommend organization",
}

@injectable()
export class FeedbackModalStore {
    isVisible: boolean = false;
    type: FeedbackModalType | null = null;

    @boundMethod
    open(type: FeedbackModalType) {
        this.isVisible = true;
        this.type = type;
    }

    @boundMethod
    close() {
        this.isVisible = false;
        this.type = null;
    }
}
