import { injectable } from "inversify";
import { load, ReCaptchaInstance } from "recaptcha-v3";

@injectable()
export class RecaptchaService {
    private recaptcha?: ReCaptchaInstance;

    public constructor(private readonly siteKey?: string) {
        //
    }

    public async verify(action: string): Promise<string> {
        const recaptcha = await this.getRecaptcha();

        if (!recaptcha) {
            console.warn("There is no recaptcha site key.");
            return "";
        }

        return recaptcha.execute(action);
    }

    private async getRecaptcha(): Promise<ReCaptchaInstance | undefined> {
        if (!this.siteKey) {
            return undefined;
        }

        if (!this.recaptcha) {
            this.recaptcha = await load(this.siteKey, {
                autoHideBadge: true,
            });
        }

        return this.recaptcha;
    }
}
