import { Api } from "../api/Api";
import { injectable } from "inversify";
import { SubmitStore } from "../stores/general/SubmitStore";
import { AdvertisingQuoteBody } from "@gsx/common/src/types/http/user/advertisingQuote";
import { marshal, Nullable } from "@gsx/common";
import { RecaptchaService } from "../utility/recaptcha/RecaptchaService";

@injectable()
export class SubmitAdvertisingQuoteStore extends SubmitStore<AdvertisingQuoteBody> {
    readonly data: Nullable<AdvertisingQuoteBody> = {
        organizationName: "",
        name: "",
        email: "",
        phoneNumber: "",
        advertisementSize: null,
        advertisementLocation: null,
    };

    constructor(private readonly api: Api, private readonly recaptchaService: RecaptchaService) {
        super();
    }

    protected async request(): Promise<void> {
        const recaptchaToken = await this.recaptchaService.verify("request_advertising_quote");
        const body = marshal(this.data);
        await this.api.user.requestAdvertisingQuote(body, recaptchaToken);
    }
}
