import { Axios, UrlFactory } from "@gsx/common";
import {
    UserVisitedOrganizationListQuery,
    UserVisitedOrganizationListResponse,
} from "@gsx/common/src/types/http/dashboard/userVisitedOrganization";

export class UserVisitedOrganizations {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/launchpad");
    }

    public async listUserVisitedOrganizations(
        params?: UserVisitedOrganizationListQuery,
    ): Promise<UserVisitedOrganizationListResponse> {
        const url = this.urlFactory.create("/userVisitedOrganizations");
        const response = await this.axios.get(url, { params });
        return response.data;
    }
}
