import { FieldOption, FieldType } from "@gsx/common";
import { Module, RegisterOptions } from "vuex-class-modules";
import { Api } from "../api/Api";
import { ApiStoreVuex } from "./general/ApiStoreVuex";

@Module()
export class ExpertiseOptionStore extends ApiStoreVuex<FieldOption[]> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch() {
        return this.api.guest.getFieldOptions(FieldType.Expertise);
    }
}
