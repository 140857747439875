import Vue from "vue";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import moment from "moment";

export const setupSentry = () => {
    if (process.env.SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            integrations: [new Integrations.Vue({ Vue })],
        });
    }
};

export const setupMoment = () => {
    // @ts-ignore
    const locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
};

export const setupCookiePolicy = () => {
    // @ts-ignore
    window.cookieconsent.initialise({
        palette: {
            popup: {
                background: "#f5f5f5",
                text: "#838391",
            },
            button: {
                background: "#19799f",
            },
        },
        theme: "classic",
        content: {
            dismiss: "Accept",
            message:
                "We use cookies to improve your user experience and support our mission. By using our site, you agree to our use of cookies. Please read our Privacy & Cookie Policy for more information.",
            href: "https://globalspaceexchange.com/privacy-policy",
        },
    });
};
