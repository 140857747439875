import { Api } from "../../../../common/src/api/Api";
import ListStoreVuex from "../../../../common/src/stores/general/ListStoreVuex";
import { Module, RegisterOptions } from "vuex-class-modules";
import { OrderListItem } from "@gsx/common/src/types/http/user/order";

@Module()
export class OrderListStore extends ListStoreVuex<OrderListItem> {
    constructor(private readonly api: Api, options: RegisterOptions) {
        super(options);
    }

    protected async fetch() {
        return this.api.user.getOrders();
    }
}
