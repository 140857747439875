import { Axios, UrlFactory } from "@gsx/common";
import {
    FeedbackListItem,
    FeedbackListQuery,
    FeedbackListResponse,
    FeedbackStatusBody,
} from "@gsx/common/src/types/http/admin/feedback";
import { adaptDate } from "../adapters";

const adaptFeedback = (data: any): FeedbackListItem => ({
    ...data,
    submissionDate: adaptDate(data.submissionDate),
});

export class Feedback {
    private readonly urlFactory: UrlFactory;

    public constructor(private readonly axios: Axios, urlFactory: UrlFactory) {
        this.urlFactory = urlFactory.to("/admin/feedback");
    }

    public async list(params: FeedbackListQuery): Promise<FeedbackListResponse> {
        const url = this.urlFactory.create("/");
        const response = await this.axios.get(url, { params });
        return {
            ...response.data,
            data: response.data.data.map(adaptFeedback),
        };
    }

    public async changeStatus(id: string, body: FeedbackStatusBody): Promise<void> {
        const url = this.urlFactory.create(`/${id}/status`);
        await this.axios.put(url, body);
    }
}
