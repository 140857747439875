import { Api } from "../../../../../common/src/api/Api";
import { injectable } from "inversify";
import { SubmitStore } from "../../../../../common/src/stores/general/SubmitStore";

@injectable()
export class ProfilePictureStore extends SubmitStore<any> {
    data: FormData = new FormData();

    constructor(private readonly api: Api) {
        super();
    }

    protected async request(): Promise<void> {
        await this.api.user.updateProfilePicture(this.data);
    }
}
