
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
// @ts-ignore
import Treeselect from "@riophae/vue-treeselect";
import FormLabel from "./FormLabel.vue";
import InputField from "./InputField.vue";
import FormFeedback from "./FormFeedback.vue";
import { TreeStructure } from "@gsx/common";
import { flattenTree, getAncestors, isNthLevel } from "../../../../common/src/utility/treeUtils";

@Component({ components: { FormFeedback, InputField, FormLabel, Treeselect } })
export default class InlineFormTreeselect extends Vue {
    @Prop() label!: string;
    @Prop() value!: string[];
    @Prop() options!: TreeStructure[];
    @Prop({ default: false }) required!: boolean;
    @Prop() help?: string;
    @Prop() error?: string;
    @Prop({ default: "text-muted" }) labelClass?: string;
    @Prop() placeholder?: string;
    @Prop() type?: string;
    @Ref("input") readonly input!: any;
    focused: boolean = false;

    get displayText(): string {
        return flattenTree(this.options)
            .filter((node) => (this.value || []).includes(node.id))
            .map((node) => node.label)
            .join(", ");
    }

    onInput(event: any[]): void {
        const values = (event || [])
            .flatMap((tag) => getAncestors(tag))
            .filter((tag) => !isNthLevel(tag, 0));

        this.$emit("input", [...new Set(values)]);
    }

    focus() {
        this.focused = true;
        this.$nextTick(() => this.input.openMenu());
        this.$emit("focus", true);
    }

    unfocus() {
        this.focused = false;
        this.$emit("focus", false);
    }
}
