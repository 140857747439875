
import { Component, Inject, Vue } from "vue-property-decorator";
import FormTextarea from "../../../landing/src/molecules/form/FormTextarea.vue";
import { Container } from "inversify";
import { NotificationStore } from "../stores/NotificationStore";
import { focusOnFirstInvalidInput } from "../form/utils";
import ActionButton from "../components/ActionButton.vue";
import Modal from "../components/Modal.vue";
import { AdvertisingQuoteModalStore } from "./AdvertisingQuoteModalStore";
import { SubmitAdvertisingQuoteStore } from "./SubmitAdvertisingQuoteStore";
import RecaptchaDisclosure from "../../../landing/src/molecules/recaptcha/RecaptchaDisclosure.vue";
import FormInput from "../../../landing/src/molecules/form/FormInput.vue";
import FormTel from "../../../landing/src/molecules/form/FormTel.vue";
import FormSelect from "../../../landing/src/molecules/form/FormSelect.vue";

@Component({
    components: {
        FormSelect,
        FormTel,
        FormInput,
        RecaptchaDisclosure,
        ActionButton,
        Modal,
        FormTextarea,
    },
})
export default class AdvertisingQuoteModal extends Vue {
    @Inject("container") readonly container!: Container;
    readonly notificationStore: NotificationStore = this.container.get(NotificationStore);
    readonly submitStore: SubmitAdvertisingQuoteStore = this.container.get(
        SubmitAdvertisingQuoteStore,
    );
    readonly modalStore: AdvertisingQuoteModalStore = this.container.get(
        AdvertisingQuoteModalStore,
    );

    get values() {
        return this.submitStore.data;
    }

    get errors() {
        return this.submitStore.formErrors;
    }

    async submit() {
        await this.submitStore.submit();

        if (this.submitStore.failed) {
            this.notificationStore.warning("Request has failed.");
            focusOnFirstInvalidInput();
        } else {
            this.notificationStore.success(
                "You have requested for an advertising quote successfully.",
            );
            this.modalStore.close();
        }
    }
}
