import { Stripe } from "../../stripe";
import { SubscriptionPlanType } from "@gsx/common/src/types/models/subscription";
import { Api } from "../../../../common/src/api/Api";
import { Product, QuiltySubscriptionPlanType, TransactionType } from "@gsx/common";

export class PaymentService {
    public constructor(private readonly api: Api, private readonly token?: string) {
        //
    }

    public async purchasePlan(
        plan: SubscriptionPlanType | QuiltySubscriptionPlanType,
    ): Promise<void> {
        const stripe = this.createStripe();

        if (!stripe) {
            return;
        }

        const { sessionId } = await this.api.payment.createSubscriptionSession({ plan });
        await stripe.redirectToCheckout({ sessionId });
    }

    public async purchaseProduct(product: Product): Promise<void> {
        const stripe = this.createStripe();

        if (!stripe) {
            return;
        }

        const { sessionId } = await this.api.payment.createProductSession({ product });
        await stripe.redirectToCheckout({ sessionId });
    }

    public async purchaseReport(reportId: string): Promise<void> {
        const stripe = this.createStripe();

        if (!stripe) {
            return;
        }

        const { sessionId } = await this.api.payment.creatReportSession(
            { product: TransactionType.Report },
            reportId,
        );
        await stripe.redirectToCheckout({ sessionId });
    }

    private createStripe(): Stripe | undefined {
        if (!window.Stripe) {
            console.warn("Cannot create Stripe instance. Stripe library is not loaded.");
            return undefined;
        }

        if (!this.token) {
            console.warn("Cannot create Stripe instance. There is no token provided.");
            return undefined;
        }

        return window.Stripe(this.token);
    }
}
